import { Unit } from '@_unit/unit/lib/Class/Unit'
import { renderBundle } from '@_unit/unit/lib/client/platform/web/render'
import { BundleSpec } from '@_unit/unit/lib/types/BundleSpec'
import { Dict } from '@_unit/unit/lib/types/Dict'
import { Unlisten } from '@_unit/unit/lib/types/Unlisten'
import { injectExtraIcons } from './icons'

import ringMenuBundle = require('./menu.json')

export type MenuOption = {
  value: string
  label: string
  icon: string
  url: string
}

export function renderMenu(
  root: HTMLElement,
  style: Dict<string>,
  options: MenuOption[],
  handlers: {
    onActionClick: (value: string) => void
    onActionEnter: (value: string) => void
    onActionLeave: (value: string) => void
  }
): [Unit, Unlisten] {
  const { onActionClick, onActionEnter, onActionLeave } = handlers

  const [system, graph, unlisten] = renderBundle(
    root,
    // @ts-ignore
    ringMenuBundle as BundleSpec
  )

  injectExtraIcons(system.icons)

  const ringMenu = graph.getUnit('ringmenu')

  ringMenu.getOutput('value').addListener('data', onActionClick)
  ringMenu.getOutput('enter').addListener('data', onActionEnter)
  ringMenu.getOutput('leave').addListener('data', onActionLeave)

  ringMenu.push('list', options)
  ringMenu.push('style', style)

  return [ringMenu, unlisten]
}
