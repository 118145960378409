import { Config, MusemeOverlayAPI, renderOverlay } from './overlay'

export function wrapVideo(
  video: HTMLVideoElement,
  videoId: string,
  config: Config = {},
  live: boolean = false
): [MusemeOverlayAPI, () => void] {
  return renderOverlay(
    {
      play(): void {
        video.play()
      },
      pause(): void {
        video.pause()
      },
      getCurrentTime(): number {
        return video.currentTime
      },
      getRect(): {
        width: number
        height: number
        top: number
        left: number
      } {
        return video.getBoundingClientRect()
      },
      isPaused(): boolean {
        return video.paused
      },
      addEventListener(event: string, callback: (event: Event) => void): void {
        video.addEventListener(event, callback)
      },
      removeEventListener(
        event: string,
        callback: (event: Event) => void
      ): void {
        video.addEventListener(event, callback)
      },
    },
    videoId,
    config,
    live
  )
}

export function wrapYtPlayer(
  player: any,
  config: Config = {},
  live: boolean = false
): [MusemeOverlayAPI, () => void] {
  const listeners = {
    play: [],
    pause: [],
    ended: [],
  }

  const videoData = player.getVideoData()

  const videoId = videoData.video_id

  player.addEventListener('onStateChange', (event) => {
    switch (event.data) {
      case 3:
        listeners.play.forEach((callback) => callback())
        break
      case 2:
        listeners.pause.forEach((callback) => callback())
        break
      case 4:
        listeners.ended.forEach((callback) => callback())
    }
  })

  return renderOverlay(
    {
      play() {
        return player.playVideo()
      },
      pause() {
        return player.pauseVideo()
      },
      getCurrentTime() {
        const currentTime = player.getCurrentTime()

        return currentTime
      },
      getRect() {
        const iframe = player.getIframe()

        const rect = iframe.getBoundingClientRect()

        return rect
      },
      isPaused() {
        return player.getPlayerState() === 2
      },
      addEventListener(event, callback) {
        switch (event) {
          case 'pause':
            listeners.pause.push(callback)
            break
          case 'play':
            listeners.play.push(callback)
            break
          case 'ended':
            listeners.ended.push(callback)
        }
      },
      removeEventListener(event, callback) {
        switch (event) {
          case 'pause':
            listeners.pause.splice(listeners.pause.indexOf(callback), 1)
            break
          case 'play':
            listeners.play.splice(listeners.play.indexOf(callback), 1)
            break
          case 'ended':
            listeners.ended.splice(listeners.ended.indexOf(callback), 1)
        }
      },
    },
    videoId,
    config,
    live
  )
}
