import icons from './icons'
import { renderOverlay } from './overlay'
import { wrapVideo } from './wrapper'

globalThis.MuseMe = {
  renderOverlay,
  wrapVideo,
  icons,
}

export default null
