{
  "spec": {
    "type": "`U`&`G`&`C`",
    "name": "untitled",
    "units": {
      "ringmenu": {
        "id": "728ccd32-1525-4e3b-979c-c13720aa6e77",
        "metadata": {
          "position": { "x": -96, "y": 43 },
          "component": {
            "width": 233.75079175970234,
            "height": 221.8116455078125
          }
        },
        "input": {
          "style": { "metadata": { "position": { "x": -246, "y": 63 } } },
          "disabled": { "metadata": { "position": { "x": -246, "y": 24 } } },
          "list": {
            "metadata": { "position": { "x": 53, "y": -1 } },
            "constant": true,
            "data": {
              "ref": [],
              "data": [
                { "value": "close", "label": "Close", "icon": "x" },
                { "value": "search", "label": "Search", "icon": "search" },
                { "value": "shop", "label": "Shop", "icon": "shopping-cart" },
                { "value": "save", "label": "Save", "icon": "save" }
              ]
            }
          }
        },
        "output": {
          "value": { "metadata": { "position": { "x": 55, "y": 58 } } },
          "leave": { "metadata": { "position": { "x": 53, "y": 88 } } },
          "enter": { "metadata": { "position": { "x": 55, "y": 29 } } }
        }
      }
    },
    "merges": {},
    "inputs": {},
    "outputs": {},
    "metadata": { "icon": null, "description": "", "complexity": 8 },
    "id": "6f887a53-16ae-44ed-aea6-2b6d85dc69d0",
    "component": {
      "subComponents": { "ringmenu": {} },
      "children": ["ringmenu"],
      "defaultWidth": 234,
      "defaultHeight": 222
    }
  },
  "specs": {
    "728ccd32-1525-4e3b-979c-c13720aa6e77": {
      "type": "`U`&`G`&`C`",
      "name": "ring menu",
      "units": {
        "merge": {
          "id": "60bdba6d-c670-430f-be72-6f39cf4e8453",
          "input": {
            "a": {
              "constant": true,
              "data": {
                "ref": [],
                "data": { "position": "absolute", "top": "0", "left": 0 }
              },
              "metadata": { "position": { "x": 122, "y": -128 } }
            },
            "b": {}
          },
          "output": { "ab": {} },
          "metadata": { "position": { "x": 81, "y": -105 } }
        },
        "mergedefault": {
          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
          "input": {
            "a": { "metadata": { "position": { "x": 271, "y": 113 } } },
            "default": {
              "constant": true,
              "metadata": { "position": { "x": 271, "y": 150 } },
              "data": {
                "ref": [],
                "data": {
                  "width": "200px",
                  "height": "200px",
                  "transition": "opacity 0.2s linear, top 0.2s linear, left 0.2s linear",
                  "position": "relative",
                  "top": "0",
                  "left": "0"
                }
              }
            }
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": 316, "y": 131 } }
        },
        "pick": {
          "id": "396497e4-f8e7-4573-95c9-a4f186a5cd05",
          "input": {
            "a": {
              "constant": true,
              "data": { "ref": [], "data": { "pointerEvents": "auto" } },
              "metadata": { "position": { "x": -76, "y": -137 } }
            },
            "b": {
              "constant": true,
              "data": { "ref": [], "data": { "pointerEvents": "none" } },
              "metadata": { "position": { "x": 3, "y": -153 } }
            },
            "c": { "metadata": { "position": { "x": -75, "y": -101 } } }
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": -29, "y": -121 } }
        },
        "listen": {
          "id": "367241d8-ad12-11ea-beed-9777f31699a8",
          "input": {
            "emitter": {},
            "event": {
              "constant": true,
              "data": { "ref": [], "data": "_click" },
              "metadata": { "position": { "x": -616, "y": -185 } }
            },
            "remove": {
              "ignored": true,
              "metadata": { "position": { "x": -622, "y": -204 } }
            }
          },
          "output": {
            "data": { "metadata": { "position": { "x": -606, "y": -216 } } }
          },
          "metadata": { "position": { "x": -565, "y": -189 } }
        },
        "div1": {
          "id": "66698a97-15c5-4602-9925-11a1aeb03710",
          "input": {
            "style": { "constant": false },
            "attr": {
              "ignored": true,
              "metadata": { "position": { "x": 322, "y": -85 } }
            }
          },
          "output": {},
          "metadata": { "position": { "x": 229, "y": -85 } }
        },
        "pie": {
          "id": "becac68c-b30c-49a7-8894-b200c2f5c5dc",
          "input": {
            "unit": {
              "constant": true,
              "data": {
                "ref": [[]],
                "data": {
                  "unit": {
                    "id": "8b5af101-cb7f-4a87-b461-b6ce15ec88c5",
                    "input": {
                      "n": { "data": "4" },
                      "r": { "data": "40" },
                      "R": { "data": "90" },
                      "y": { "data": "100" },
                      "x": { "data": "100" },
                      "icon": { "data": "\"save\"" },
                      "value": { "data": "\"save\"" },
                      "i": { "data": "3" }
                    }
                  },
                  "specs": {
                    "d222eb61-6d6d-467b-b82e-449d46b339b3": {
                      "type": "`U`&`G`&`C`",
                      "name": "replace obj",
                      "units": {
                        "foreachkeyvalue": {
                          "id": "47771360-640a-11ea-8393-d32f60372182",
                          "input": {
                            "obj": {
                              "metadata": {
                                "position": { "x": -125, "y": -34 }
                              }
                            }
                          },
                          "output": {
                            "key": {},
                            "value": {},
                            "test": { "ignored": false }
                          },
                          "metadata": { "position": { "x": -46, "y": -29 } }
                        },
                        "replaceglobal4": {
                          "id": "3d9dbbe5-672c-4a8e-b4af-1bd41e63ee81",
                          "input": {
                            "regex": { "constant": false },
                            "str": {},
                            "with": {}
                          },
                          "metadata": { "position": { "x": -23, "y": 28 } },
                          "output": { "str": {} }
                        },
                        "loop": {
                          "id": "3432494e-fab6-4e9d-a846-0c48a7c429cd",
                          "input": {
                            "next": {},
                            "test": {},
                            "init": {
                              "constant": false,
                              "metadata": { "position": { "x": 89, "y": -7 } }
                            }
                          },
                          "output": {
                            "current": {},
                            "local": {
                              "ignored": true,
                              "metadata": { "position": { "x": 71, "y": -28 } }
                            },
                            "final": {
                              "ignored": false,
                              "metadata": { "position": { "x": 90, "y": 26 } }
                            }
                          },
                          "metadata": { "position": { "x": 69, "y": 2 } }
                        }
                      },
                      "merges": {
                        "5": {
                          "foreachkeyvalue": { "output": { "test": true } },
                          "loop": { "input": { "test": true } }
                        },
                        "6": {
                          "replaceglobal4": { "input": { "str": true } },
                          "loop": { "output": { "current": true } }
                        },
                        "7": {
                          "replaceglobal4": { "input": { "with": true } },
                          "foreachkeyvalue": { "output": { "value": true } }
                        },
                        "8": {
                          "foreachkeyvalue": { "output": { "key": true } },
                          "replaceglobal4": { "input": { "regex": true } }
                        },
                        "9": {
                          "replaceglobal4": { "output": { "str": true } },
                          "loop": { "input": { "next": true } }
                        }
                      },
                      "inputs": {
                        "obj": {
                          "plug": {
                            "0": { "unitId": "foreachkeyvalue", "pinId": "obj" }
                          },
                          "ref": false,
                          "functional": true
                        },
                        "str": {
                          "plug": {
                            "0": { "unitId": "loop", "pinId": "init" }
                          },
                          "ref": false,
                          "functional": true
                        }
                      },
                      "outputs": {
                        "str": {
                          "plug": {
                            "0": { "unitId": "loop", "pinId": "final" }
                          },
                          "ref": false
                        }
                      },
                      "metadata": { "icon": "question", "description": "" },
                      "id": "d222eb61-6d6d-467b-b82e-449d46b339b3"
                    },
                    "3d9dbbe5-672c-4a8e-b4af-1bd41e63ee81": {
                      "type": "`U`&`G`&`C`",
                      "name": "replace global",
                      "units": {
                        "replace": {
                          "id": "af5469c4-a09e-4b22-8133-a21e721e7639",
                          "input": {
                            "str": { "ignored": false, "constant": false },
                            "regex": {
                              "ignored": false,
                              "constant": false,
                              "data": "\"x0\""
                            },
                            "flags": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"g\""
                            },
                            "with": { "ignored": false, "constant": false }
                          },
                          "output": {
                            "str": { "ignored": false, "constant": false }
                          }
                        }
                      },
                      "merges": {},
                      "inputs": {
                        "str": {
                          "plug": {
                            "0": { "unitId": "replace", "pinId": "str" }
                          },
                          "ref": false
                        },
                        "regex": {
                          "plug": {
                            "0": { "unitId": "replace", "pinId": "regex" }
                          },
                          "ref": false
                        },
                        "with": {
                          "plug": {
                            "0": { "unitId": "replace", "pinId": "with" }
                          },
                          "ref": false
                        }
                      },
                      "outputs": {
                        "str": {
                          "plug": {
                            "0": { "unitId": "replace", "pinId": "str" }
                          },
                          "ref": false
                        }
                      },
                      "metadata": { "icon": null, "description": "" },
                      "id": "3d9dbbe5-672c-4a8e-b4af-1bd41e63ee81"
                    },
                    "8a41cd0c-a2de-409c-a8fe-71d7a7d9a96a": {
                      "type": "`U`&`G`&`C`",
                      "name": "make pie obj",
                      "units": {
                        "pointincircle1": {
                          "id": "14104461-269c-4d73-9345-563438d9e46a",
                          "input": {
                            "rad": {
                              "metadata": {
                                "position": {
                                  "x": 64.30869945964525,
                                  "y": -684.2475924063356
                                }
                              }
                            },
                            "x": {
                              "metadata": {
                                "position": { "x": 147, "y": -89 }
                              },
                              "constant": false
                            },
                            "y": {
                              "metadata": {
                                "position": { "x": 117, "y": -106 }
                              }
                            },
                            "r": {
                              "metadata": { "position": { "x": 157, "y": -63 } }
                            }
                          },
                          "output": {
                            "x": {
                              "metadata": {
                                "position": {
                                  "x": 155.8133171179178,
                                  "y": -654.0221456420934
                                }
                              }
                            },
                            "y": {
                              "metadata": {
                                "position": {
                                  "x": 141.42873948509634,
                                  "y": -623.7966988778626
                                }
                              }
                            }
                          },
                          "metadata": { "position": { "x": 105, "y": -81 } }
                        },
                        "degtorad0": {
                          "id": "3a138358-cc25-45e3-a0ce-bbe54445736f",
                          "input": { "angle": {} },
                          "output": { "rad": {} },
                          "metadata": { "position": { "x": -96, "y": 57 } }
                        },
                        "pieangles": {
                          "id": "1adde6cb-0840-463d-91a4-01a255a72ab6",
                          "input": {
                            "i": {
                              "metadata": { "position": { "x": 44, "y": 157 } }
                            },
                            "n": {
                              "metadata": { "position": { "x": -36, "y": 168 } }
                            }
                          },
                          "output": {
                            "a0": {
                              "metadata": {
                                "position": {
                                  "x": -165.41397469272465,
                                  "y": -390.9961029695701
                                }
                              }
                            },
                            "a1": {
                              "metadata": {
                                "position": {
                                  "x": -257.10301947074004,
                                  "y": -354.86898994730905
                                }
                              }
                            }
                          },
                          "metadata": { "position": { "x": 2, "y": 114 } }
                        },
                        "makepie": {
                          "id": "0130c7af-e013-4e0f-a20c-276878069764",
                          "input": {
                            "r": {
                              "metadata": { "position": { "x": -17, "y": -92 } }
                            },
                            "R": {
                              "metadata": { "position": { "x": 12, "y": -90 } }
                            },
                            "y3": {},
                            "x0": {},
                            "x3": {},
                            "y1": {},
                            "y2": {},
                            "x1": {},
                            "x2": {},
                            "y0": {}
                          },
                          "metadata": { "position": { "x": -1, "y": -63 } },
                          "output": {
                            "obj": {
                              "metadata": { "position": { "x": -40, "y": -78 } }
                            }
                          }
                        },
                        "pointincircle0": {
                          "id": "14104461-269c-4d73-9345-563438d9e46a",
                          "input": {
                            "rad": {
                              "metadata": {
                                "position": {
                                  "x": 64.30869945964525,
                                  "y": -684.2475924063356
                                }
                              }
                            },
                            "x": {
                              "metadata": { "position": { "x": -48, "y": 57 } },
                              "constant": false
                            },
                            "y": {
                              "metadata": { "position": { "x": -145, "y": 38 } }
                            },
                            "r": {
                              "metadata": { "position": { "x": -132, "y": 60 } }
                            }
                          },
                          "output": {
                            "x": {
                              "metadata": {
                                "position": {
                                  "x": 155.8133171179178,
                                  "y": -654.0221456420934
                                }
                              }
                            },
                            "y": {
                              "metadata": {
                                "position": {
                                  "x": 141.42873948509634,
                                  "y": -623.7966988778626
                                }
                              }
                            }
                          },
                          "metadata": { "position": { "x": -83, "y": 9 } }
                        },
                        "pointincircle2": {
                          "id": "14104461-269c-4d73-9345-563438d9e46a",
                          "input": {
                            "rad": {
                              "metadata": {
                                "position": {
                                  "x": 64.30869945964525,
                                  "y": -684.2475924063356
                                }
                              }
                            },
                            "x": {
                              "metadata": { "position": { "x": 170, "y": -2 } },
                              "constant": false
                            },
                            "y": {
                              "metadata": { "position": { "x": 170, "y": 24 } }
                            },
                            "r": {
                              "metadata": { "position": { "x": 149, "y": 45 } }
                            }
                          },
                          "output": {
                            "x": {
                              "metadata": {
                                "position": {
                                  "x": 155.8133171179178,
                                  "y": -654.0221456420934
                                }
                              }
                            },
                            "y": {
                              "metadata": {
                                "position": {
                                  "x": 141.42873948509634,
                                  "y": -623.7966988778626
                                }
                              }
                            }
                          },
                          "metadata": { "position": { "x": 120, "y": -14 } }
                        },
                        "degtorad1": {
                          "id": "3a138358-cc25-45e3-a0ce-bbe54445736f",
                          "input": { "angle": {} },
                          "output": { "rad": {} },
                          "metadata": { "position": { "x": 77, "y": 40 } }
                        },
                        "pointincircle": {
                          "id": "14104461-269c-4d73-9345-563438d9e46a",
                          "input": {
                            "rad": {
                              "metadata": {
                                "position": { "x": -179, "y": -58 }
                              }
                            },
                            "x": {
                              "metadata": {
                                "position": { "x": -177, "y": -60 }
                              },
                              "constant": false
                            },
                            "y": {
                              "metadata": {
                                "position": { "x": -156, "y": -82 }
                              }
                            },
                            "r": {
                              "metadata": {
                                "position": { "x": -180, "y": -32 }
                              }
                            }
                          },
                          "output": {
                            "x": {
                              "metadata": {
                                "position": {
                                  "x": 155.8133171179178,
                                  "y": -654.0221456420934
                                }
                              }
                            },
                            "y": {
                              "metadata": {
                                "position": {
                                  "x": 141.42873948509634,
                                  "y": -623.7966988778626
                                }
                              }
                            }
                          },
                          "metadata": { "position": { "x": -122, "y": -62 } }
                        }
                      },
                      "merges": {
                        "0": {
                          "pointincircle": { "output": { "x": true } },
                          "makepie": { "input": { "x0": true } }
                        },
                        "1": {
                          "pointincircle": { "output": { "y": true } },
                          "makepie": { "input": { "y0": true } }
                        },
                        "2": {
                          "pointincircle0": { "output": { "x": true } },
                          "makepie": { "input": { "x1": true } }
                        },
                        "3": {
                          "pointincircle0": { "output": { "y": true } },
                          "makepie": { "input": { "y1": true } }
                        },
                        "4": {
                          "pointincircle1": { "output": { "x": true } },
                          "makepie": { "input": { "x2": true } }
                        },
                        "5": {
                          "pointincircle1": { "output": { "y": true } },
                          "makepie": { "input": { "y2": true } }
                        },
                        "6": {
                          "pointincircle2": { "output": { "x": true } },
                          "makepie": { "input": { "x3": true } }
                        },
                        "7": {
                          "pointincircle2": { "output": { "y": true } },
                          "makepie": { "input": { "y3": true } }
                        },
                        "8": {
                          "degtorad0": { "input": { "angle": true } },
                          "pieangles": { "output": { "a0": true } }
                        },
                        "9": {
                          "degtorad1": { "input": { "angle": true } },
                          "pieangles": { "output": { "a1": true } }
                        },
                        "10": {
                          "degtorad0": { "output": { "rad": true } },
                          "pointincircle0": { "input": { "rad": true } },
                          "pointincircle": { "input": { "rad": true } }
                        },
                        "12": {
                          "degtorad1": { "output": { "rad": true } },
                          "pointincircle1": { "input": { "rad": true } },
                          "pointincircle2": { "input": { "rad": true } }
                        }
                      },
                      "inputs": {
                        "y": {
                          "plug": {
                            "0": { "unitId": "pointincircle2", "pinId": "y" },
                            "1": { "unitId": "pointincircle0", "pinId": "y" },
                            "3": { "unitId": "pointincircle", "pinId": "y" },
                            "4": { "unitId": "pointincircle1", "pinId": "y" }
                          },
                          "ref": false
                        },
                        "x": {
                          "plug": {
                            "0": { "unitId": "pointincircle1", "pinId": "x" },
                            "2": { "unitId": "pointincircle2", "pinId": "x" },
                            "3": { "unitId": "pointincircle0", "pinId": "x" },
                            "4": { "unitId": "pointincircle", "pinId": "x" }
                          },
                          "ref": false
                        },
                        "n": {
                          "plug": {
                            "0": { "unitId": "pieangles", "pinId": "n" }
                          },
                          "ref": false
                        },
                        "i": {
                          "plug": {
                            "0": { "unitId": "pieangles", "pinId": "i" }
                          },
                          "ref": false
                        },
                        "R": {
                          "plug": {
                            "0": { "unitId": "pointincircle1", "pinId": "r" },
                            "1": { "unitId": "makepie", "pinId": "R" },
                            "2": { "unitId": "pointincircle0", "pinId": "r" }
                          },
                          "ref": false
                        },
                        "r": {
                          "plug": {
                            "0": { "unitId": "makepie", "pinId": "r" },
                            "1": { "unitId": "pointincircle2", "pinId": "r" },
                            "2": { "unitId": "pointincircle", "pinId": "r" }
                          },
                          "ref": false
                        }
                      },
                      "outputs": {
                        "obj": {
                          "plug": {
                            "0": { "unitId": "makepie", "pinId": "obj" }
                          },
                          "ref": false
                        }
                      },
                      "metadata": { "icon": "question", "description": "" },
                      "id": "8a41cd0c-a2de-409c-a8fe-71d7a7d9a96a"
                    },
                    "14104461-269c-4d73-9345-563438d9e46a": {
                      "name": "point in circle",
                      "units": {
                        "sin": {
                          "id": "b9f90a95-1bfc-4897-9068-2560743821a3",
                          "metadata": { "position": { "x": 99, "y": -45 } },
                          "input": { "a": {} },
                          "output": { "sin(a)": {} }
                        },
                        "cos": {
                          "id": "62af44dc-17d6-459a-b95f-3b8ce5b48856",
                          "metadata": { "position": { "x": 4, "y": -67 } },
                          "input": { "a": {} },
                          "output": { "cos(a)": {} }
                        },
                        "multiply": {
                          "id": "872be100-f4dc-4246-8eea-6e3da31137b7",
                          "input": { "b": { "constant": false }, "a": {} },
                          "metadata": { "position": { "x": -73, "y": -7 } },
                          "output": { "a × b": {} }
                        },
                        "multiply0": {
                          "id": "872be100-f4dc-4246-8eea-6e3da31137b7",
                          "input": { "b": { "constant": false }, "a": {} },
                          "metadata": { "position": { "x": 31, "y": 29 } },
                          "output": { "a × b": {} }
                        },
                        "add": {
                          "id": "6fe452f2-2ec1-4ee2-887d-751c3697e6bf",
                          "input": {
                            "b": {
                              "constant": false,
                              "metadata": {
                                "position": { "x": -206, "y": -38 }
                              }
                            },
                            "a": {}
                          },
                          "output": {
                            "a + b": {
                              "metadata": { "position": { "x": -206, "y": -2 } }
                            }
                          },
                          "metadata": { "position": { "x": -179, "y": -4 } }
                        },
                        "subtract": {
                          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
                          "input": {
                            "a": {
                              "constant": false,
                              "metadata": { "position": { "x": 186, "y": 84 } }
                            },
                            "b": {}
                          },
                          "output": {
                            "a - b": {
                              "metadata": { "position": { "x": 97, "y": 104 } }
                            }
                          },
                          "metadata": { "position": { "x": 117, "y": 94 } }
                        }
                      },
                      "merges": {
                        "0": {
                          "multiply0": { "input": { "a": true } },
                          "sin": { "output": { "sin(a)": true } }
                        },
                        "1": {
                          "cos": { "output": { "cos(a)": true } },
                          "multiply": { "input": { "a": true } }
                        },
                        "2": {
                          "add": { "input": { "a": true } },
                          "multiply": { "output": { "a × b": true } }
                        },
                        "4": {
                          "cos": { "input": { "a": true } },
                          "sin": { "input": { "a": true } }
                        },
                        "6": {
                          "multiply0": { "output": { "a × b": true } },
                          "subtract": { "input": { "b": true } }
                        },
                        "7": {
                          "multiply0": { "input": { "b": true } },
                          "multiply": { "input": { "b": true } }
                        }
                      },
                      "metadata": {
                        "icon": null,
                        "description": "no description",
                        "tags": [
                          "platform",
                          "core",
                          "math",
                          "geometry",
                          "trigonometry"
                        ]
                      },
                      "inputs": {
                        "rad": {
                          "plug": { "0": { "mergeId": "4" } },
                          "type": "number"
                        },
                        "x": {
                          "plug": { "0": { "unitId": "add", "pinId": "b" } },
                          "functional": true,
                          "type": "number"
                        },
                        "y": {
                          "plug": {
                            "0": { "unitId": "subtract", "pinId": "a" }
                          },
                          "functional": true,
                          "type": "number"
                        },
                        "r": {
                          "plug": { "0": { "mergeId": "7" } },
                          "type": "number"
                        }
                      },
                      "outputs": {
                        "x": {
                          "plug": {
                            "0": { "unitId": "add", "pinId": "a + b" }
                          },
                          "type": "number",
                          "ref": false
                        },
                        "y": {
                          "plug": {
                            "0": { "unitId": "subtract", "pinId": "a - b" }
                          },
                          "type": "number",
                          "ref": false
                        }
                      },
                      "id": "14104461-269c-4d73-9345-563438d9e46a",
                      "type": "`U`&`G`"
                    },
                    "1adde6cb-0840-463d-91a4-01a255a72ab6": {
                      "type": "`U`&`G`&`C`",
                      "name": "pie angles",
                      "units": {
                        "divide": {
                          "id": "158d85eb-28b8-4355-b637-28a7ec751233",
                          "input": {
                            "a": {
                              "constant": true,
                              "data": "360",
                              "metadata": {
                                "position": { "x": -180, "y": -13 }
                              }
                            },
                            "b": {
                              "metadata": {
                                "position": { "x": -170, "y": -45 }
                              }
                            }
                          },
                          "output": { "a ÷ b": {} },
                          "metadata": { "position": { "x": -111, "y": -37 } }
                        },
                        "subtract": {
                          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
                          "input": {
                            "a": {
                              "constant": true,
                              "data": "180",
                              "metadata": { "position": { "x": -161, "y": 63 } }
                            },
                            "b": {}
                          },
                          "output": { "a - b": {} },
                          "metadata": { "position": { "x": -94, "y": 30 } }
                        },
                        "half": {
                          "id": "49d04556-34fd-4231-a478-0e106b564e1c",
                          "input": { "a": {} },
                          "output": { "a ÷ 2": {} },
                          "metadata": { "position": { "x": -42, "y": 98 } }
                        },
                        "add": {
                          "id": "6fe452f2-2ec1-4ee2-887d-751c3697e6bf",
                          "input": { "a": {}, "b": {} },
                          "output": { "a + b": {} },
                          "metadata": { "position": { "x": 30, "y": 41 } }
                        },
                        "multiply": {
                          "id": "872be100-f4dc-4246-8eea-6e3da31137b7",
                          "input": {
                            "a": {},
                            "b": {
                              "metadata": { "position": { "x": -70, "y": -61 } }
                            }
                          },
                          "output": { "a × b": {} },
                          "metadata": { "position": { "x": -23, "y": -43 } }
                        },
                        "add0": {
                          "id": "6fe452f2-2ec1-4ee2-887d-751c3697e6bf",
                          "input": { "a": {}, "b": {} },
                          "output": {
                            "a + b": {
                              "metadata": { "position": { "x": 139, "y": -71 } }
                            }
                          },
                          "metadata": { "position": { "x": 18, "y": -23 } }
                        },
                        "subtract0": {
                          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
                          "input": {
                            "a": {
                              "constant": true,
                              "data": "360",
                              "metadata": {
                                "position": { "x": 117, "y": -109 }
                              }
                            },
                            "b": {}
                          },
                          "output": {
                            "a - b": {
                              "metadata": { "position": { "x": 134, "y": -78 } }
                            }
                          },
                          "metadata": { "position": { "x": 100, "y": -97 } }
                        },
                        "subtract1": {
                          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
                          "input": {
                            "a": {
                              "ignored": false,
                              "constant": true,
                              "data": "360",
                              "metadata": { "position": { "x": 156, "y": 43 } }
                            },
                            "b": { "ignored": false, "constant": false }
                          },
                          "output": {
                            "a - b": {
                              "ignored": false,
                              "constant": false,
                              "metadata": { "position": { "x": 145, "y": 75 } }
                            }
                          },
                          "metadata": { "position": { "x": 122, "y": 30 } }
                        }
                      },
                      "merges": {
                        "0": {
                          "divide": { "output": { "a ÷ b": true } },
                          "subtract": { "input": { "b": true } },
                          "multiply": { "input": { "a": true } },
                          "add0": { "input": { "b": true } }
                        },
                        "1": {
                          "half": { "input": { "a": true } },
                          "subtract": { "output": { "a - b": true } }
                        },
                        "2": {
                          "add": { "input": { "a": true } },
                          "half": { "output": { "a ÷ 2": true } }
                        },
                        "3": {
                          "add": { "input": { "b": true } },
                          "multiply": { "output": { "a × b": true } }
                        },
                        "5": {
                          "add0": { "input": { "a": true } },
                          "add": { "output": { "a + b": true } },
                          "subtract1": { "input": { "b": true } }
                        },
                        "7": {
                          "subtract0": { "input": { "b": true } },
                          "add0": { "output": { "a + b": true } }
                        }
                      },
                      "inputs": {
                        "n": {
                          "plug": { "0": { "unitId": "divide", "pinId": "b" } },
                          "functional": true,
                          "ref": false
                        },
                        "i": {
                          "plug": {
                            "0": { "unitId": "multiply", "pinId": "b" }
                          },
                          "ref": false,
                          "functional": true
                        }
                      },
                      "outputs": {
                        "a0": {
                          "plug": {
                            "0": { "unitId": "subtract1", "pinId": "a - b" }
                          },
                          "ref": false
                        },
                        "a1": {
                          "plug": {
                            "0": { "unitId": "subtract0", "pinId": "a - b" }
                          },
                          "ref": false
                        }
                      },
                      "metadata": { "icon": "question", "description": "" },
                      "id": "1adde6cb-0840-463d-91a4-01a255a72ab6",
                      "component": {}
                    },
                    "0130c7af-e013-4e0f-a20c-276878069764": {
                      "type": "`U`&`G`&`C`",
                      "name": "make pie",
                      "units": {
                        "set": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": {
                              "ignored": false,
                              "constant": true,
                              "data": "{}",
                              "metadata": { "position": { "x": 178, "y": 187 } }
                            },
                            "key": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"x0\"",
                              "metadata": { "position": { "x": 95, "y": 183 } }
                            },
                            "value": {
                              "ignored": false,
                              "constant": false,
                              "metadata": { "position": { "x": 87, "y": 152 } }
                            }
                          },
                          "output": {
                            "obj": { "ignored": false, "constant": false }
                          },
                          "metadata": { "position": { "x": 135, "y": 157 } }
                        },
                        "set0": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": { "ignored": false, "constant": false },
                            "key": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"y0\"",
                              "metadata": { "position": { "x": 273, "y": 107 } }
                            },
                            "value": {
                              "ignored": false,
                              "constant": false,
                              "metadata": { "position": { "x": 274, "y": 75 } }
                            }
                          },
                          "output": {
                            "obj": { "ignored": false, "constant": false }
                          },
                          "metadata": { "position": { "x": 222, "y": 87 } }
                        },
                        "set1": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": { "ignored": false, "constant": false },
                            "key": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"x1\"",
                              "metadata": { "position": { "x": 101, "y": 25 } }
                            },
                            "value": {
                              "ignored": false,
                              "constant": false,
                              "metadata": { "position": { "x": 101, "y": -3 } }
                            }
                          },
                          "output": {
                            "obj": { "ignored": false, "constant": false }
                          },
                          "metadata": { "position": { "x": 146, "y": 7 } }
                        },
                        "set2": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": { "ignored": false, "constant": false },
                            "key": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"y1\"",
                              "metadata": { "position": { "x": 255, "y": -71 } }
                            },
                            "value": {
                              "ignored": false,
                              "constant": false,
                              "metadata": {
                                "position": { "x": 251, "y": -103 }
                              }
                            }
                          },
                          "output": {
                            "obj": { "ignored": false, "constant": false }
                          },
                          "metadata": { "position": { "x": 202, "y": -85 } }
                        },
                        "set4": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": { "ignored": false, "constant": false },
                            "key": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"x3\"",
                              "metadata": {
                                "position": { "x": -138, "y": -61 }
                              }
                            },
                            "value": {
                              "ignored": false,
                              "constant": false,
                              "metadata": { "position": { "x": -39, "y": -37 } }
                            }
                          },
                          "output": {
                            "obj": { "ignored": false, "constant": false }
                          },
                          "metadata": { "position": { "x": -90, "y": -52 } }
                        },
                        "set5": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": { "ignored": false, "constant": false },
                            "key": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"y2\"",
                              "metadata": {
                                "position": { "x": -55, "y": -140 }
                              }
                            },
                            "value": {
                              "ignored": false,
                              "constant": false,
                              "metadata": {
                                "position": { "x": -28, "y": -168 }
                              }
                            }
                          },
                          "output": {
                            "obj": { "ignored": false, "constant": false }
                          },
                          "metadata": { "position": { "x": -9, "y": -130 } }
                        },
                        "set6": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": { "ignored": false, "constant": false },
                            "key": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"y3\"",
                              "metadata": { "position": { "x": -89, "y": 83 } }
                            },
                            "value": {
                              "ignored": false,
                              "constant": false,
                              "metadata": { "position": { "x": -73, "y": 53 } }
                            }
                          },
                          "output": {
                            "obj": { "ignored": false, "constant": false }
                          },
                          "metadata": { "position": { "x": -128, "y": 47 } }
                        },
                        "set3": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": { "ignored": false, "constant": false },
                            "key": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"x2\"",
                              "metadata": { "position": { "x": 80, "y": -177 } }
                            },
                            "value": {
                              "ignored": false,
                              "constant": false,
                              "metadata": {
                                "position": { "x": 149, "y": -168 }
                              }
                            }
                          },
                          "output": {
                            "obj": { "ignored": false, "constant": false }
                          },
                          "metadata": { "position": { "x": 105, "y": -144 } }
                        },
                        "set7": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": { "ignored": false, "constant": false },
                            "key": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"R\"",
                              "metadata": {
                                "position": { "x": -200, "y": 111 }
                              }
                            },
                            "value": {
                              "ignored": false,
                              "constant": false,
                              "metadata": {
                                "position": { "x": -271, "y": 109 }
                              }
                            }
                          },
                          "output": {
                            "obj": { "ignored": false, "constant": false }
                          },
                          "metadata": { "position": { "x": -238, "y": 76 } }
                        },
                        "set8": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": { "ignored": false, "constant": false },
                            "key": {
                              "ignored": false,
                              "constant": true,
                              "data": "\"r\"",
                              "metadata": { "position": { "x": -389, "y": 56 } }
                            },
                            "value": {
                              "ignored": false,
                              "constant": false,
                              "metadata": { "position": { "x": -388, "y": 21 } }
                            }
                          },
                          "output": {
                            "obj": {
                              "ignored": false,
                              "constant": false,
                              "metadata": { "position": { "x": -309, "y": 6 } }
                            }
                          },
                          "metadata": { "position": { "x": -345, "y": 37 } }
                        }
                      },
                      "merges": {
                        "0": {
                          "set8": { "input": { "obj": true } },
                          "set7": { "output": { "obj": true } }
                        },
                        "1": {
                          "set7": { "input": { "obj": true } },
                          "set6": { "output": { "obj": true } }
                        },
                        "5": {
                          "set0": { "input": { "obj": true } },
                          "set": { "output": { "obj": true } }
                        },
                        "6": {
                          "set1": { "input": { "obj": true } },
                          "set0": { "output": { "obj": true } }
                        },
                        "7": {
                          "set2": { "input": { "obj": true } },
                          "set1": { "output": { "obj": true } }
                        },
                        "8": {
                          "set3": { "input": { "obj": true } },
                          "set2": { "output": { "obj": true } }
                        },
                        "9": {
                          "set5": { "input": { "obj": true } },
                          "set3": { "output": { "obj": true } }
                        },
                        "10": {
                          "set4": { "input": { "obj": true } },
                          "set5": { "output": { "obj": true } }
                        },
                        "11": {
                          "set6": { "input": { "obj": true } },
                          "set4": { "output": { "obj": true } }
                        }
                      },
                      "inputs": {
                        "y3": {
                          "plug": {
                            "0": { "unitId": "set6", "pinId": "value" }
                          },
                          "functional": true
                        },
                        "x0": {
                          "plug": {
                            "0": { "unitId": "set", "pinId": "value" }
                          },
                          "functional": true
                        },
                        "x3": {
                          "plug": {
                            "0": { "unitId": "set4", "pinId": "value" }
                          },
                          "functional": true
                        },
                        "y1": {
                          "plug": {
                            "0": { "unitId": "set2", "pinId": "value" }
                          },
                          "functional": true
                        },
                        "y2": {
                          "plug": {
                            "0": { "unitId": "set5", "pinId": "value" }
                          },
                          "functional": true
                        },
                        "x1": {
                          "plug": {
                            "0": { "unitId": "set1", "pinId": "value" }
                          },
                          "functional": true
                        },
                        "x2": {
                          "plug": {
                            "0": { "unitId": "set3", "pinId": "value" }
                          },
                          "functional": true
                        },
                        "y0": {
                          "plug": {
                            "0": { "unitId": "set0", "pinId": "value" }
                          },
                          "functional": true
                        },
                        "r": {
                          "plug": {
                            "0": { "unitId": "set8", "pinId": "value" }
                          },
                          "type": "any",
                          "functional": true
                        },
                        "R": {
                          "plug": {
                            "0": { "unitId": "set7", "pinId": "value" }
                          },
                          "type": "any",
                          "ref": false
                        }
                      },
                      "outputs": {
                        "obj": {
                          "plug": { "0": { "unitId": "set8", "pinId": "obj" } },
                          "type": "object"
                        }
                      },
                      "metadata": { "icon": null, "description": "" },
                      "id": "0130c7af-e013-4e0f-a20c-276878069764"
                    },
                    "6f36579a-24b3-40f4-bd85-dcb9ffff65f9": {
                      "type": "`U`&`G`&`C`",
                      "name": "pie icon",
                      "units": {
                        "mergedefault0": {
                          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
                          "input": {
                            "a": {
                              "metadata": { "position": { "x": 222, "y": 4 } }
                            },
                            "default": {
                              "constant": true,
                              "data": "{\"width\":\"15%\",\"height\":\"15%\",\"position\":\"absolute\",\"strokeWidth\":\"2px\",\"zIndex\":\"3\",\"pointerEvents\":\"none\"}",
                              "metadata": { "position": { "x": 222, "y": 41 } }
                            }
                          },
                          "output": { "a": {} },
                          "metadata": { "position": { "x": 156, "y": 16 } }
                        },
                        "icon": {
                          "id": "63a417e5-d354-4b39-9ebd-05f55e70de7b",
                          "input": {
                            "style": {},
                            "icon": {
                              "metadata": { "position": { "x": -80, "y": 51 } }
                            },
                            "attr": { "ignored": false }
                          },
                          "output": {},
                          "metadata": {
                            "position": { "x": 0, "y": 16 },
                            "component": {
                              "width": 140.59765625,
                              "height": 166.62109375
                            }
                          }
                        },
                        "mergedefault": {
                          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
                          "input": {
                            "a": {
                              "metadata": {
                                "position": { "x": -102, "y": -55 }
                              }
                            },
                            "default": {
                              "constant": true,
                              "data": "{\"width\":\"30\",\"height\":\"30px\"}",
                              "metadata": {
                                "position": { "x": -186, "y": -26 }
                              }
                            }
                          },
                          "output": { "a": {} },
                          "metadata": { "position": { "x": -156, "y": -31 } }
                        }
                      },
                      "merges": {
                        "0": {
                          "mergedefault": { "output": { "a": true } },
                          "icon": { "input": { "attr": true } }
                        },
                        "9": {
                          "mergedefault0": { "output": { "a": true } },
                          "icon": { "input": { "style": true } }
                        }
                      },
                      "inputs": {
                        "icon": {
                          "plug": {
                            "0": { "unitId": "icon", "pinId": "icon" }
                          },
                          "ref": false
                        },
                        "style": {
                          "plug": {
                            "0": { "unitId": "mergedefault0", "pinId": "a" }
                          },
                          "ref": false
                        },
                        "attr": {
                          "plug": {
                            "0": {
                              "unitId": "mergedefault",
                              "pinId": "a",
                              "kind": "input"
                            }
                          },
                          "ref": false
                        }
                      },
                      "outputs": {},
                      "metadata": { "icon": "question", "description": "" },
                      "render": true,
                      "component": {
                        "subComponents": {
                          "icon": {
                            "children": [],
                            "childSlot": {},
                            "width": 45,
                            "height": 45
                          }
                        },
                        "children": ["icon"],
                        "defaultWidth": 45,
                        "defaultHeight": 44.87109375
                      },
                      "id": "6f36579a-24b3-40f4-bd85-dcb9ffff65f9"
                    },
                    "0a5bd454-2b17-48b8-b39e-8f7cb6782986": {
                      "type": "`U`&`G`&`C`",
                      "name": "get icon position",
                      "units": {
                        "degtorad": {
                          "id": "3a138358-cc25-45e3-a0ce-bbe54445736f",
                          "input": { "angle": {} },
                          "output": { "rad": {} },
                          "metadata": { "position": { "x": -59, "y": -8 } }
                        },
                        "average": {
                          "id": "635a946a-b11e-450e-99a8-5cbe8928cb0c",
                          "output": { "ab2": {} },
                          "metadata": { "position": { "x": -164, "y": -7 } },
                          "input": { "a": {}, "b": {} }
                        },
                        "pointincircle": {
                          "id": "14104461-269c-4d73-9345-563438d9e46a",
                          "input": {
                            "rad": {},
                            "x": {
                              "metadata": { "position": { "x": 0, "y": -38 } }
                            },
                            "y": {
                              "metadata": { "position": { "x": 10, "y": 29 } }
                            },
                            "r": {}
                          },
                          "output": {
                            "x": {
                              "metadata": { "position": { "x": 63, "y": -48 } }
                            },
                            "y": {
                              "metadata": { "position": { "x": 86, "y": -22 } }
                            }
                          },
                          "metadata": { "position": { "x": 54, "y": -15 } }
                        },
                        "pieangles": {
                          "id": "1adde6cb-0840-463d-91a4-01a255a72ab6",
                          "input": {
                            "n": {
                              "metadata": {
                                "position": { "x": -324, "y": -18 }
                              }
                            },
                            "i": {
                              "metadata": { "position": { "x": -324, "y": 17 } }
                            }
                          },
                          "output": { "a0": {}, "a1": {} },
                          "metadata": { "position": { "x": -261, "y": -7 } }
                        },
                        "average0": {
                          "id": "635a946a-b11e-450e-99a8-5cbe8928cb0c",
                          "input": {
                            "a": {
                              "constant": false,
                              "ignored": false,
                              "metadata": { "position": { "x": 300, "y": 35 } }
                            },
                            "b": {
                              "constant": false,
                              "ignored": false,
                              "metadata": { "position": { "x": 297, "y": -1 } }
                            }
                          },
                          "output": {
                            "ab2": { "constant": false, "ignored": false }
                          },
                          "metadata": { "position": { "x": 269, "y": 13 } }
                        },
                        "add": {
                          "id": "6fe452f2-2ec1-4ee2-887d-751c3697e6bf",
                          "input": {
                            "a": {},
                            "b": {
                              "constant": true,
                              "data": "0",
                              "metadata": { "position": { "x": 161, "y": 71 } }
                            }
                          },
                          "output": { "a + b": {} },
                          "metadata": { "position": { "x": 161, "y": 23 } }
                        }
                      },
                      "merges": {
                        "0": {
                          "average0": { "output": { "ab2": true } },
                          "add": { "input": { "a": true } }
                        },
                        "1": {
                          "add": { "output": { "a + b": true } },
                          "pointincircle": { "input": { "r": true } }
                        },
                        "9": {
                          "degtorad": { "input": { "angle": true } },
                          "average": { "output": { "ab2": true } }
                        },
                        "10": {
                          "degtorad": { "output": { "rad": true } },
                          "pointincircle": { "input": { "rad": true } }
                        },
                        "13": {
                          "pieangles": { "output": { "a0": true } },
                          "average": { "input": { "a": true } }
                        },
                        "14": {
                          "pieangles": { "output": { "a1": true } },
                          "average": { "input": { "b": true } }
                        }
                      },
                      "inputs": {
                        "x": {
                          "plug": {
                            "0": { "unitId": "pointincircle", "pinId": "x" }
                          },
                          "ref": false
                        },
                        "y": {
                          "plug": {
                            "0": { "unitId": "pointincircle", "pinId": "y" }
                          },
                          "ref": false
                        },
                        "n": {
                          "plug": {
                            "0": { "unitId": "pieangles", "pinId": "n" }
                          },
                          "ref": false
                        },
                        "i": {
                          "plug": {
                            "0": { "unitId": "pieangles", "pinId": "i" }
                          },
                          "ref": false
                        },
                        "r": {
                          "plug": {
                            "0": { "unitId": "average0", "pinId": "a" }
                          },
                          "ref": false
                        },
                        "R": {
                          "plug": {
                            "0": { "unitId": "average0", "pinId": "b" }
                          },
                          "ref": false
                        }
                      },
                      "outputs": {
                        "x": {
                          "plug": {
                            "0": { "unitId": "pointincircle", "pinId": "x" }
                          },
                          "ref": false
                        },
                        "y": {
                          "plug": {
                            "0": { "unitId": "pointincircle", "pinId": "y" }
                          },
                          "ref": false
                        }
                      },
                      "metadata": { "icon": "question", "description": "" },
                      "id": "0a5bd454-2b17-48b8-b39e-8f7cb6782986",
                      "component": {}
                    },
                    "635a946a-b11e-450e-99a8-5cbe8928cb0c": {
                      "type": "`U`&`G`&`C`",
                      "name": "average",
                      "units": {
                        "half": {
                          "id": "49d04556-34fd-4231-a478-0e106b564e1c",
                          "input": { "a": {} },
                          "output": { "a ÷ 2": {} }
                        },
                        "add": {
                          "id": "6fe452f2-2ec1-4ee2-887d-751c3697e6bf",
                          "input": { "a": {}, "b": {} },
                          "output": { "a + b": {} }
                        }
                      },
                      "merges": {
                        "9": {
                          "add": { "output": { "a + b": true } },
                          "half": { "input": { "a": true } }
                        }
                      },
                      "inputs": {
                        "a": {
                          "plug": { "0": { "unitId": "add", "pinId": "a" } },
                          "ref": false
                        },
                        "b": {
                          "plug": { "0": { "unitId": "add", "pinId": "b" } },
                          "ref": false
                        }
                      },
                      "outputs": {
                        "ab2": {
                          "plug": {
                            "0": { "unitId": "half", "pinId": "a ÷ 2" }
                          },
                          "ref": false
                        }
                      },
                      "metadata": { "icon": "question", "description": "" },
                      "id": "635a946a-b11e-450e-99a8-5cbe8928cb0c"
                    },
                    "8b5af101-cb7f-4a87-b461-b6ce15ec88c5": {
                      "type": "`U`&`G`&`C`",
                      "name": "pie slice",
                      "units": {
                        "replaceobj": {
                          "id": "d222eb61-6d6d-467b-b82e-449d46b339b3",
                          "input": {
                            "str": {
                              "constant": true,
                              "data": "\"M x0 y0 L x1 y1 A R R 1 0 1 x2 y2 L x3 y3 A r r 1 0 0 x0 y0 z\"",
                              "metadata": {
                                "position": { "x": 349, "y": -213 }
                              }
                            },
                            "obj": {
                              "metadata": {
                                "position": {
                                  "x": 240.16663705069618,
                                  "y": 292.5034882068733
                                }
                              },
                              "constant": false
                            }
                          },
                          "output": {
                            "str": {
                              "metadata": {
                                "position": {
                                  "x": 335.9109233539747,
                                  "y": 273.9188943479159
                                }
                              }
                            }
                          },
                          "metadata": { "position": { "x": 310, "y": -235 } }
                        },
                        "makepieobj": {
                          "id": "8a41cd0c-a2de-409c-a8fe-71d7a7d9a96a",
                          "output": {
                            "obj": {
                              "metadata": {
                                "position": {
                                  "x": -234.5201399096004,
                                  "y": 355.69273127470234
                                }
                              }
                            }
                          },
                          "metadata": { "position": { "x": 434, "y": -258 } },
                          "input": {
                            "y": {
                              "metadata": {
                                "position": { "x": 559, "y": -221 }
                              },
                              "constant": false
                            },
                            "x": {
                              "metadata": {
                                "position": { "x": 565, "y": -192 }
                              },
                              "constant": false
                            },
                            "n": {
                              "metadata": {
                                "position": { "x": 471, "y": -229 }
                              },
                              "constant": false
                            },
                            "i": {
                              "metadata": {
                                "position": { "x": 548, "y": -165 }
                              },
                              "constant": false
                            },
                            "R": {
                              "metadata": {
                                "position": { "x": 529, "y": -244 }
                              },
                              "constant": false
                            },
                            "r": {
                              "metadata": {
                                "position": { "x": 484, "y": -160 }
                              },
                              "constant": false
                            }
                          }
                        },
                        "onpointerenter": {
                          "id": "c0bb493a-af78-11ea-b6fa-3b893b757a39",
                          "input": { "element": {} },
                          "output": { "event": {} },
                          "metadata": { "position": { "x": -16, "y": -91 } }
                        },
                        "onpointerleave": {
                          "id": "c7dba94e-af78-11ea-b7d7-47e14ca215b5",
                          "input": { "element": {} },
                          "output": { "event": {} },
                          "metadata": { "position": { "x": 252, "y": -92 } }
                        },
                        "mergedefault": {
                          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
                          "input": {
                            "a": {
                              "metadata": {
                                "position": { "x": -378, "y": -234 }
                              }
                            },
                            "default": {}
                          },
                          "output": { "a": {} },
                          "metadata": { "position": { "x": -252, "y": -28 } }
                        },
                        "true": {
                          "id": "984c4a44-86c7-464c-ad48-4759598c3be3",
                          "input": { "any": {} },
                          "output": { "true": {} },
                          "metadata": { "position": { "x": 59, "y": -36 } }
                        },
                        "false": {
                          "id": "b4d20f6f-32dc-4d04-97d6-69518976bdea",
                          "input": { "any": {} },
                          "output": { "false": {} },
                          "metadata": { "position": { "x": 168, "y": -31 } }
                        },
                        "iterate": {
                          "id": "8a2b756a-25e4-11eb-860d-1f34c850b992",
                          "input": {
                            "next": {},
                            "init": {
                              "constant": true,
                              "data": "false",
                              "metadata": {
                                "position": { "x": -104, "y": -110 }
                              }
                            }
                          },
                          "output": {
                            "current": {},
                            "local": {
                              "ignored": true,
                              "metadata": {
                                "position": { "x": -166, "y": -164 }
                              }
                            }
                          },
                          "metadata": { "position": { "x": 56, "y": 25 } }
                        },
                        "pick0": {
                          "id": "396497e4-f8e7-4573-95c9-a4f186a5cd05",
                          "input": {
                            "a": {
                              "constant": true,
                              "data": "{\"color\":\"#26bbf8\",\"zIndex\":\"1\"}",
                              "metadata": {
                                "position": { "x": -218, "y": -148 }
                              }
                            },
                            "b": {
                              "constant": true,
                              "data": "{\"color\":\"currentcolor\",\"zIndex\":\"0\"}",
                              "metadata": {
                                "position": { "x": -288, "y": -148 }
                              }
                            },
                            "c": {}
                          },
                          "output": { "a": {} },
                          "metadata": { "position": { "x": -46, "y": 79 } }
                        },
                        "pieicon": {
                          "id": "6f36579a-24b3-40f4-bd85-dcb9ffff65f9",
                          "input": {
                            "style": {
                              "metadata": { "position": { "x": 64, "y": 283 } }
                            },
                            "icon": {
                              "metadata": { "position": { "x": 231, "y": 316 } }
                            },
                            "attr": {}
                          },
                          "metadata": {
                            "position": { "x": 260, "y": 522 },
                            "component": {
                              "width": 105.47265625,
                              "height": 177.48828125
                            }
                          },
                          "output": {}
                        },
                        "geticonposition": {
                          "id": "0a5bd454-2b17-48b8-b39e-8f7cb6782986",
                          "input": {
                            "x": {
                              "metadata": {
                                "position": { "x": -229, "y": 472 }
                              }
                            },
                            "y": {
                              "metadata": {
                                "position": { "x": -180, "y": 461 }
                              }
                            },
                            "n": {
                              "metadata": {
                                "position": { "x": -260, "y": 453 }
                              }
                            },
                            "i": {
                              "metadata": {
                                "position": { "x": -169, "y": 410 }
                              }
                            },
                            "r": {
                              "metadata": {
                                "position": { "x": -271, "y": 428 }
                              }
                            },
                            "R": {
                              "metadata": {
                                "position": { "x": -261, "y": 403 }
                              }
                            }
                          },
                          "metadata": { "position": { "x": -114, "y": 522 } },
                          "output": { "x": {}, "y": {} }
                        },
                        "set": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": { "constant": false },
                            "key": {
                              "constant": true,
                              "data": "\"y\"",
                              "metadata": { "position": { "x": 40, "y": 377 } }
                            },
                            "value": {}
                          },
                          "output": {
                            "obj": {
                              "metadata": { "position": { "x": 98, "y": 29 } }
                            }
                          },
                          "metadata": { "position": { "x": 111, "y": 519 } }
                        },
                        "set0": {
                          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
                          "input": {
                            "obj": {
                              "constant": true,
                              "data": "{}",
                              "metadata": {
                                "position": { "x": -130, "y": 255 }
                              }
                            },
                            "key": {
                              "constant": true,
                              "data": "\"x\"",
                              "metadata": { "position": { "x": -57, "y": 258 } }
                            },
                            "value": {}
                          },
                          "output": { "obj": {} },
                          "metadata": { "position": { "x": 67, "y": 419 } }
                        },
                        "pick": {
                          "id": "396497e4-f8e7-4573-95c9-a4f186a5cd05",
                          "input": {
                            "a": {
                              "constant": true,
                              "data": "{\"fill\":\"#1f1f1fdd\"}",
                              "metadata": { "position": { "x": -210, "y": 1 } }
                            },
                            "b": {
                              "constant": true,
                              "data": "{\"fill\":\"#1f1f1faa\"}",
                              "metadata": {
                                "position": { "x": -232, "y": -27 }
                              }
                            },
                            "c": {}
                          },
                          "output": { "a": {} },
                          "metadata": { "position": { "x": 75, "y": 91 } }
                        },
                        "mergedefault0": {
                          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
                          "input": {
                            "a": {},
                            "default": {
                              "constant": true,
                              "metadata": {
                                "position": { "x": -418, "y": -128 }
                              },
                              "data": "{\"position\":\"absolute\",\"left\":\"0\",\"top\":\"0\",\"pointerEvents\":\"none\",\"width\":\"100%\",\"height\":\"100%\"}"
                            }
                          },
                          "output": { "a": {} },
                          "metadata": { "position": { "x": -159, "y": 35 } }
                        },
                        "path": {
                          "id": "60bc7930-8982-11ea-8b24-cf97a783bb32",
                          "input": {
                            "style": {
                              "metadata": { "position": { "x": 152, "y": 26 } },
                              "ignored": false,
                              "constant": false
                            },
                            "d": {
                              "metadata": { "position": { "x": 152, "y": 64 } }
                            },
                            "fillRule": {
                              "ignored": true,
                              "metadata": {
                                "position": { "x": 333, "y": -130 }
                              }
                            }
                          },
                          "output": {},
                          "metadata": { "position": { "x": 117, "y": -176 } }
                        },
                        "svg": {
                          "id": "98c279fc-7f5e-11ea-b9e4-f772f9f0bed1",
                          "input": {
                            "style": {
                              "metadata": {
                                "position": { "x": -322, "y": -13 }
                              }
                            },
                            "viewBox": {
                              "ignored": false,
                              "metadata": {
                                "position": { "x": -405, "y": -370 }
                              },
                              "constant": true,
                              "data": "\"0 0 200 200\""
                            }
                          },
                          "output": {},
                          "metadata": { "position": { "x": -402, "y": -193 } }
                        },
                        "mergedefault2": {
                          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
                          "input": {
                            "a": {},
                            "default": {
                              "constant": true,
                              "data": "{\"cursor\":\"pointer\",\"fill\":\"#000000aa\",\"strokeWidth\":\"2px\",\"pointerEvents\":\"auto\"}",
                              "metadata": { "position": { "x": -39, "y": 29 } }
                            }
                          },
                          "output": { "a": {} },
                          "metadata": { "position": { "x": 158, "y": 15 } }
                        },
                        "subtract": {
                          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
                          "input": {
                            "a": {},
                            "b": {
                              "constant": true,
                              "data": "15",
                              "metadata": {
                                "position": { "x": -250, "y": 299 }
                              }
                            }
                          },
                          "output": { "a - b": {} },
                          "metadata": { "position": { "x": -44, "y": 433 } }
                        },
                        "subtract0": {
                          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
                          "input": {
                            "a": { "ignored": false, "constant": false },
                            "b": {
                              "ignored": false,
                              "constant": true,
                              "data": "15",
                              "metadata": { "position": { "x": -50, "y": 451 } }
                            }
                          },
                          "output": {
                            "a - b": { "ignored": false, "constant": false }
                          },
                          "metadata": { "position": { "x": 4, "y": 559 } }
                        },
                        "onclick": {
                          "id": "97c94516-add1-11ea-ba72-8f55299b735c",
                          "input": { "element": {} },
                          "output": { "event": {} },
                          "metadata": { "position": { "x": -260, "y": -148 } }
                        },
                        "dispatch": {
                          "id": "a1cd3c3c-b1fa-40a2-80d1-32f416264a3d",
                          "input": {
                            "element": {},
                            "event": {
                              "constant": true,
                              "data": "\"click\"",
                              "metadata": {
                                "position": { "x": 117, "y": -284 }
                              }
                            },
                            "data": {},
                            "opt": {
                              "constant": true,
                              "data": "{}",
                              "metadata": {
                                "position": { "x": 100, "y": -310 }
                              }
                            }
                          },
                          "output": {},
                          "metadata": { "position": { "x": -39, "y": -137 } }
                        },
                        "wait": {
                          "id": "ba38b0af-80c0-49e4-9e39-864396964ccc",
                          "input": {
                            "a": {
                              "constant": true,
                              "metadata": { "position": { "x": 9, "y": -366 } }
                            },
                            "b": {}
                          },
                          "output": { "a": {} },
                          "metadata": { "position": { "x": -158, "y": -124 } }
                        },
                        "stoppropagation": {
                          "id": "d0f94e4e-43f4-4c0a-b07e-ca40c82f48f4",
                          "input": {
                            "component": {},
                            "name": { "constant": true, "data": "\"click\"" },
                            "done": { "ignored": true }
                          },
                          "output": {},
                          "metadata": { "position": { "x": 261, "y": -158 } }
                        },
                        "onpointerenter0": {
                          "id": "c0bb493a-af78-11ea-b6fa-3b893b757a39",
                          "input": { "element": {} },
                          "output": { "event": {} },
                          "metadata": { "position": { "x": -255, "y": -275 } }
                        },
                        "wait0": {
                          "id": "ba38b0af-80c0-49e4-9e39-864396964ccc",
                          "input": {
                            "a": {
                              "constant": true,
                              "metadata": { "position": { "x": 9, "y": -366 } }
                            },
                            "b": {}
                          },
                          "output": { "a": {} },
                          "metadata": { "position": { "x": -144, "y": -325 } }
                        },
                        "dispatch0": {
                          "id": "a1cd3c3c-b1fa-40a2-80d1-32f416264a3d",
                          "input": {
                            "element": {},
                            "event": {
                              "constant": true,
                              "data": "\"enter\"",
                              "metadata": {
                                "position": { "x": 117, "y": -284 }
                              }
                            },
                            "data": {},
                            "opt": {
                              "constant": true,
                              "data": "{}",
                              "metadata": {
                                "position": { "x": 100, "y": -310 }
                              }
                            }
                          },
                          "output": {},
                          "metadata": { "position": { "x": -24, "y": -295 } }
                        },
                        "onpointerleave0": {
                          "id": "c7dba94e-af78-11ea-b7d7-47e14ca215b5",
                          "input": { "element": {} },
                          "output": { "event": {} },
                          "metadata": { "position": { "x": -254, "y": -202 } }
                        },
                        "wait1": {
                          "id": "ba38b0af-80c0-49e4-9e39-864396964ccc",
                          "input": {
                            "a": {
                              "constant": true,
                              "metadata": { "position": { "x": 9, "y": -366 } }
                            },
                            "b": {}
                          },
                          "output": { "a": {} },
                          "metadata": { "position": { "x": -147, "y": -217 } }
                        },
                        "dispatch1": {
                          "id": "a1cd3c3c-b1fa-40a2-80d1-32f416264a3d",
                          "input": {
                            "element": {},
                            "event": {
                              "constant": true,
                              "data": "\"leave\"",
                              "metadata": {
                                "position": { "x": 117, "y": -284 }
                              }
                            },
                            "data": {},
                            "opt": {
                              "constant": true,
                              "data": "{}",
                              "metadata": {
                                "position": { "x": 100, "y": -310 }
                              }
                            }
                          },
                          "output": {},
                          "metadata": { "position": { "x": -32, "y": -212 } }
                        }
                      },
                      "merges": {
                        "0": {
                          "mergedefault": { "output": { "a": true } },
                          "svg": { "input": { "style": true } }
                        },
                        "1": {
                          "replaceobj": { "output": { "str": true } },
                          "path": { "input": { "d": true } }
                        },
                        "2": {
                          "replaceobj": { "input": { "obj": true } },
                          "makepieobj": { "output": { "obj": true } }
                        },
                        "3": {
                          "onpointerleave": { "output": { "event": true } },
                          "false": { "input": { "any": true } }
                        },
                        "4": {
                          "onpointerenter": { "output": { "event": true } },
                          "true": { "input": { "any": true } }
                        },
                        "5": {
                          "true": { "output": { "true": true } },
                          "false": { "output": { "false": true } },
                          "iterate": { "input": { "next": true } }
                        },
                        "6": {
                          "onpointerenter": { "input": { "element": true } },
                          "onpointerleave": { "input": { "element": true } },
                          "path": { "output": { "_self": true } },
                          "dispatch": { "input": { "element": true } },
                          "stoppropagation": { "input": { "component": true } },
                          "dispatch1": { "input": { "element": true } },
                          "dispatch0": { "input": { "element": true } }
                        },
                        "7": {
                          "pick0": { "input": { "c": true } },
                          "iterate": { "output": { "current": true } },
                          "pick": { "input": { "c": true } }
                        },
                        "8": {
                          "mergedefault0": { "output": { "a": true } },
                          "mergedefault": { "input": { "default": true } }
                        },
                        "9": {
                          "subtract": { "input": { "a": true } },
                          "geticonposition": { "output": { "x": true } }
                        },
                        "10": {
                          "geticonposition": { "output": { "y": true } },
                          "subtract0": { "input": { "a": true } }
                        },
                        "11": {
                          "set": { "output": { "obj": true } },
                          "pieicon": { "input": { "attr": true } }
                        },
                        "12": {
                          "set": { "input": { "obj": true } },
                          "set0": { "output": { "obj": true } }
                        },
                        "13": {
                          "subtract0": { "output": { "a - b": true } },
                          "set": { "input": { "value": true } }
                        },
                        "14": {
                          "mergedefault2": { "output": { "a": true } },
                          "path": { "input": { "style": true } }
                        },
                        "15": {
                          "pick": { "output": { "a": true } },
                          "mergedefault2": { "input": { "a": true } }
                        },
                        "16": {
                          "wait0": { "input": { "b": true } },
                          "onpointerenter0": { "output": { "event": true } }
                        },
                        "17": {
                          "subtract": { "output": { "a - b": true } },
                          "set0": { "input": { "value": true } }
                        },
                        "18": {
                          "wait": { "input": { "b": true } },
                          "onclick": { "output": { "event": true } }
                        },
                        "19": {
                          "wait": { "output": { "a": true } },
                          "dispatch": { "input": { "data": true } }
                        },
                        "20": {
                          "mergedefault0": { "input": { "a": true } },
                          "pick0": { "output": { "a": true } }
                        },
                        "21": {
                          "onclick": { "input": { "element": true } },
                          "svg": { "output": { "_self": true } },
                          "onpointerenter0": { "input": { "element": true } },
                          "onpointerleave0": { "input": { "element": true } }
                        },
                        "22": {
                          "wait1": { "input": { "b": true } },
                          "onpointerleave0": { "output": { "event": true } }
                        },
                        "23": {
                          "dispatch1": { "input": { "data": true } },
                          "wait1": { "output": { "a": true } }
                        },
                        "24": {
                          "dispatch0": { "input": { "data": true } },
                          "wait0": { "output": { "a": true } }
                        }
                      },
                      "inputs": {
                        "x": {
                          "plug": {
                            "0": { "unitId": "makepieobj", "pinId": "x" },
                            "1": { "unitId": "geticonposition", "pinId": "x" }
                          },
                          "ref": false
                        },
                        "n": {
                          "plug": {
                            "0": { "unitId": "makepieobj", "pinId": "n" },
                            "1": { "unitId": "geticonposition", "pinId": "n" }
                          },
                          "ref": false
                        },
                        "r": {
                          "plug": {
                            "0": { "unitId": "makepieobj", "pinId": "r" },
                            "1": { "unitId": "geticonposition", "pinId": "r" }
                          },
                          "ref": false
                        },
                        "R": {
                          "plug": {
                            "0": { "unitId": "makepieobj", "pinId": "R" },
                            "1": { "unitId": "geticonposition", "pinId": "R" }
                          },
                          "ref": false
                        },
                        "i": {
                          "plug": {
                            "0": { "unitId": "makepieobj", "pinId": "i" },
                            "1": { "unitId": "geticonposition", "pinId": "i" }
                          },
                          "ref": false
                        },
                        "y": {
                          "plug": {
                            "0": { "unitId": "makepieobj", "pinId": "y" },
                            "1": { "unitId": "geticonposition", "pinId": "y" }
                          },
                          "ref": false
                        },
                        "style": {
                          "plug": {
                            "0": { "unitId": "mergedefault", "pinId": "a" }
                          },
                          "ref": false
                        },
                        "icon": {
                          "plug": {
                            "0": { "unitId": "pieicon", "pinId": "icon" },
                            "1": {
                              "unitId": "wait1",
                              "pinId": "a",
                              "kind": "input"
                            },
                            "2": {
                              "unitId": "wait0",
                              "pinId": "a",
                              "kind": "input"
                            },
                            "3": {
                              "unitId": "wait",
                              "pinId": "a",
                              "kind": "input"
                            }
                          },
                          "type": "string",
                          "ref": false
                        },
                        "value": { "plug": { "0": {} }, "type": "any" }
                      },
                      "outputs": {},
                      "metadata": {
                        "icon": "question",
                        "description": "",
                        "complexity": 129
                      },
                      "render": true,
                      "component": {
                        "subComponents": {
                          "pieicon": {},
                          "path": {},
                          "svg": { "children": ["path", "pieicon"] }
                        },
                        "children": ["svg"],
                        "defaultWidth": 110.77777777777777,
                        "defaultHeight": 107.77777777777777
                      },
                      "id": "8b5af101-cb7f-4a87-b461-b6ce15ec88c5"
                    }
                  }
                }
              },
              "metadata": { "position": { "x": -536, "y": -215 } },
              "ignored": false
            },
            "style": {
              "metadata": { "position": { "x": -540, "y": -146 } },
              "constant": false,
              "ignored": false
            },
            "list": {
              "constant": false,
              "metadata": { "position": { "x": -277, "y": -105 } },
              "ignored": false
            },
            "r": {
              "constant": true,
              "data": { "ref": [], "data": 40 },
              "metadata": { "position": { "x": -277, "y": -167 } }
            },
            "R": {
              "constant": true,
              "data": { "ref": [], "data": 90 },
              "metadata": { "position": { "x": -275, "y": -137 } }
            }
          },
          "metadata": {
            "component": {
              "width": 204.01870727539062,
              "height": 210.1319580078125
            },
            "position": { "x": -409, "y": -158 }
          },
          "output": {}
        },
        "div0": {
          "id": "66698a97-15c5-4602-9925-11a1aeb03710",
          "input": {
            "style": { "constant": false },
            "attr": {
              "ignored": true,
              "metadata": { "position": { "x": 680, "y": 131 } }
            }
          },
          "output": {},
          "metadata": {
            "component": { "width": 246, "height": 232 },
            "position": { "x": 525, "y": 131 }
          }
        },
        "listen0": {
          "id": "367241d8-ad12-11ea-beed-9777f31699a8",
          "input": {
            "emitter": { "ignored": false, "constant": false },
            "event": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": "_leave" },
              "metadata": { "position": { "x": -221, "y": -238 } }
            },
            "remove": {
              "ignored": true,
              "constant": false,
              "metadata": { "position": { "x": -204, "y": -225 } }
            }
          },
          "output": {
            "data": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": -208, "y": -205 } }
            }
          },
          "metadata": { "position": { "x": -259, "y": -208 } }
        },
        "listen1": {
          "id": "367241d8-ad12-11ea-beed-9777f31699a8",
          "input": {
            "emitter": { "ignored": false, "constant": false },
            "event": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": "_enter" },
              "metadata": { "position": { "x": -579, "y": -50 } }
            },
            "remove": {
              "ignored": true,
              "constant": false,
              "metadata": { "position": { "x": -599, "y": -61 } }
            }
          },
          "output": {
            "data": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": -603, "y": -81 } }
            }
          },
          "metadata": { "position": { "x": -553, "y": -88 } }
        }
      },
      "merges": {
        "2": {
          "div0": { "input": { "style": true } },
          "mergedefault": { "output": { "a": true } }
        },
        "13": {
          "merge": { "input": { "b": true } },
          "pick": { "output": { "a": true } }
        },
        "14": {
          "listen": { "input": { "emitter": true } },
          "pie": { "output": { "_self": true } },
          "listen0": { "input": { "emitter": true } },
          "listen1": { "input": { "emitter": true } }
        },
        "15": {
          "merge": { "output": { "ab": true } },
          "div1": { "input": { "style": true } }
        }
      },
      "inputs": {
        "style": {
          "plug": { "0": { "unitId": "mergedefault", "pinId": "a" } },
          "metadata": { "position": { "0": { "ext": { "x": 254, "y": 97 } } } }
        },
        "disabled": {
          "plug": { "0": { "unitId": "pick", "pinId": "c", "kind": "input" } },
          "ref": false,
          "metadata": { "position": { "0": { "ext": { "x": -92, "y": -85 } } } }
        },
        "list": {
          "plug": {
            "0": { "unitId": "pie", "pinId": "list", "kind": "input" }
          },
          "type": "object[]",
          "ref": false,
          "metadata": {
            "position": { "0": { "ext": { "x": -269, "y": -83 } } }
          }
        }
      },
      "outputs": {
        "value": {
          "plug": {
            "0": { "unitId": "listen", "pinId": "data", "kind": "output" }
          },
          "ref": false,
          "metadata": {
            "position": { "0": { "ext": { "x": -622, "y": -235 } } }
          }
        },
        "leave": {
          "plug": {
            "0": { "unitId": "listen0", "pinId": "data", "kind": "output" }
          },
          "ref": false,
          "metadata": {
            "position": { "0": { "ext": { "x": -183, "y": -204 } } }
          }
        },
        "enter": {
          "plug": {
            "0": { "unitId": "listen1", "pinId": "data", "kind": "output" }
          },
          "ref": false,
          "metadata": {
            "position": { "0": { "ext": { "x": -625, "y": -71 } } }
          }
        }
      },
      "metadata": {
        "icon": "question",
        "description": "",
        "position": {
          "merge": {
            "2": { "x": 368, "y": 131 },
            "13": { "x": 26, "y": -110 },
            "15": { "x": 132, "y": -90 }
          }
        }
      },
      "render": true,
      "component": {
        "subComponents": {
          "div1": {
            "children": [],
            "childSlot": {},
            "width": 120,
            "height": 120
          },
          "pie": {
            "children": [],
            "childSlot": {},
            "width": 204.01870727539062,
            "height": 210.1319580078125
          },
          "div0": {
            "children": ["pie", "div1"],
            "childSlot": {},
            "width": 205.49297925970234,
            "height": 179.5433908933808
          }
        },
        "children": ["div0"],
        "defaultWidth": 205.49297925970234,
        "defaultHeight": 179.8793283933808
      },
      "id": "728ccd32-1525-4e3b-979c-c13720aa6e77"
    },
    "d222eb61-6d6d-467b-b82e-449d46b339b3": {
      "type": "`U`&`G`&`C`",
      "name": "replace obj",
      "units": {
        "foreachkeyvalue": {
          "id": "47771360-640a-11ea-8393-d32f60372182",
          "input": {
            "obj": { "metadata": { "position": { "x": -125, "y": -34 } } }
          },
          "output": { "key": {}, "value": {}, "test": { "ignored": false } },
          "metadata": { "position": { "x": -46, "y": -29 } }
        },
        "replaceglobal4": {
          "id": "3d9dbbe5-672c-4a8e-b4af-1bd41e63ee81",
          "input": { "regex": { "constant": false }, "str": {}, "with": {} },
          "metadata": { "position": { "x": -23, "y": 28 } },
          "output": { "str": {} }
        },
        "loop": {
          "id": "3432494e-fab6-4e9d-a846-0c48a7c429cd",
          "input": {
            "next": {},
            "test": {},
            "init": {
              "constant": false,
              "metadata": { "position": { "x": 89, "y": -7 } }
            }
          },
          "output": {
            "current": {},
            "local": {
              "ignored": true,
              "metadata": { "position": { "x": 71, "y": -28 } }
            },
            "final": {
              "ignored": false,
              "metadata": { "position": { "x": 90, "y": 26 } }
            }
          },
          "metadata": { "position": { "x": 69, "y": 2 } }
        }
      },
      "merges": {
        "5": {
          "foreachkeyvalue": { "output": { "test": true } },
          "loop": { "input": { "test": true } }
        },
        "6": {
          "replaceglobal4": { "input": { "str": true } },
          "loop": { "output": { "current": true } }
        },
        "7": {
          "replaceglobal4": { "input": { "with": true } },
          "foreachkeyvalue": { "output": { "value": true } }
        },
        "8": {
          "foreachkeyvalue": { "output": { "key": true } },
          "replaceglobal4": { "input": { "regex": true } }
        },
        "9": {
          "replaceglobal4": { "output": { "str": true } },
          "loop": { "input": { "next": true } }
        }
      },
      "inputs": {
        "obj": {
          "plug": { "0": { "unitId": "foreachkeyvalue", "pinId": "obj" } },
          "ref": false,
          "functional": true
        },
        "str": {
          "plug": { "0": { "unitId": "loop", "pinId": "init" } },
          "ref": false,
          "functional": true
        }
      },
      "outputs": {
        "str": {
          "plug": { "0": { "unitId": "loop", "pinId": "final" } },
          "ref": false
        }
      },
      "metadata": { "icon": "question", "description": "" },
      "id": "d222eb61-6d6d-467b-b82e-449d46b339b3"
    },
    "3d9dbbe5-672c-4a8e-b4af-1bd41e63ee81": {
      "type": "`U`&`G`&`C`",
      "name": "replace global",
      "units": {
        "replace": {
          "id": "af5469c4-a09e-4b22-8133-a21e721e7639",
          "input": {
            "str": { "ignored": false, "constant": false },
            "regex": { "ignored": false, "constant": false, "data": "\"x0\"" },
            "flags": { "ignored": false, "constant": true, "data": "\"g\"" },
            "with": { "ignored": false, "constant": false }
          },
          "output": { "str": { "ignored": false, "constant": false } }
        }
      },
      "merges": {},
      "inputs": {
        "str": {
          "plug": { "0": { "unitId": "replace", "pinId": "str" } },
          "ref": false
        },
        "regex": {
          "plug": { "0": { "unitId": "replace", "pinId": "regex" } },
          "ref": false
        },
        "with": {
          "plug": { "0": { "unitId": "replace", "pinId": "with" } },
          "ref": false
        }
      },
      "outputs": {
        "str": {
          "plug": { "0": { "unitId": "replace", "pinId": "str" } },
          "ref": false
        }
      },
      "metadata": { "icon": null, "description": "" },
      "id": "3d9dbbe5-672c-4a8e-b4af-1bd41e63ee81"
    },
    "8a41cd0c-a2de-409c-a8fe-71d7a7d9a96a": {
      "type": "`U`&`G`&`C`",
      "name": "make pie obj",
      "units": {
        "pointincircle1": {
          "id": "14104461-269c-4d73-9345-563438d9e46a",
          "input": {
            "rad": {
              "metadata": {
                "position": { "x": 64.30869945964525, "y": -684.2475924063356 }
              }
            },
            "x": {
              "metadata": { "position": { "x": 147, "y": -89 } },
              "constant": false
            },
            "y": { "metadata": { "position": { "x": 117, "y": -106 } } },
            "r": { "metadata": { "position": { "x": 157, "y": -63 } } }
          },
          "output": {
            "x": {
              "metadata": {
                "position": { "x": 155.8133171179178, "y": -654.0221456420934 }
              }
            },
            "y": {
              "metadata": {
                "position": { "x": 141.42873948509634, "y": -623.7966988778626 }
              }
            }
          },
          "metadata": { "position": { "x": 105, "y": -81 } }
        },
        "degtorad0": {
          "id": "3a138358-cc25-45e3-a0ce-bbe54445736f",
          "input": { "angle": {} },
          "output": { "rad": {} },
          "metadata": { "position": { "x": -96, "y": 57 } }
        },
        "pieangles": {
          "id": "1adde6cb-0840-463d-91a4-01a255a72ab6",
          "input": {
            "i": { "metadata": { "position": { "x": 44, "y": 157 } } },
            "n": { "metadata": { "position": { "x": -36, "y": 168 } } }
          },
          "output": {
            "a0": {
              "metadata": {
                "position": {
                  "x": -165.41397469272465,
                  "y": -390.9961029695701
                }
              }
            },
            "a1": {
              "metadata": {
                "position": {
                  "x": -257.10301947074004,
                  "y": -354.86898994730905
                }
              }
            }
          },
          "metadata": { "position": { "x": 2, "y": 114 } }
        },
        "makepie": {
          "id": "0130c7af-e013-4e0f-a20c-276878069764",
          "input": {
            "r": { "metadata": { "position": { "x": -17, "y": -92 } } },
            "R": { "metadata": { "position": { "x": 12, "y": -90 } } },
            "y3": {},
            "x0": {},
            "x3": {},
            "y1": {},
            "y2": {},
            "x1": {},
            "x2": {},
            "y0": {}
          },
          "metadata": { "position": { "x": -1, "y": -63 } },
          "output": {
            "obj": { "metadata": { "position": { "x": -40, "y": -78 } } }
          }
        },
        "pointincircle0": {
          "id": "14104461-269c-4d73-9345-563438d9e46a",
          "input": {
            "rad": {
              "metadata": {
                "position": { "x": 64.30869945964525, "y": -684.2475924063356 }
              }
            },
            "x": {
              "metadata": { "position": { "x": -48, "y": 57 } },
              "constant": false
            },
            "y": { "metadata": { "position": { "x": -145, "y": 38 } } },
            "r": { "metadata": { "position": { "x": -132, "y": 60 } } }
          },
          "output": {
            "x": {
              "metadata": {
                "position": { "x": 155.8133171179178, "y": -654.0221456420934 }
              }
            },
            "y": {
              "metadata": {
                "position": { "x": 141.42873948509634, "y": -623.7966988778626 }
              }
            }
          },
          "metadata": { "position": { "x": -83, "y": 9 } }
        },
        "pointincircle2": {
          "id": "14104461-269c-4d73-9345-563438d9e46a",
          "input": {
            "rad": {
              "metadata": {
                "position": { "x": 64.30869945964525, "y": -684.2475924063356 }
              }
            },
            "x": {
              "metadata": { "position": { "x": 170, "y": -2 } },
              "constant": false
            },
            "y": { "metadata": { "position": { "x": 170, "y": 24 } } },
            "r": { "metadata": { "position": { "x": 149, "y": 45 } } }
          },
          "output": {
            "x": {
              "metadata": {
                "position": { "x": 155.8133171179178, "y": -654.0221456420934 }
              }
            },
            "y": {
              "metadata": {
                "position": { "x": 141.42873948509634, "y": -623.7966988778626 }
              }
            }
          },
          "metadata": { "position": { "x": 120, "y": -14 } }
        },
        "degtorad1": {
          "id": "3a138358-cc25-45e3-a0ce-bbe54445736f",
          "input": { "angle": {} },
          "output": { "rad": {} },
          "metadata": { "position": { "x": 77, "y": 40 } }
        },
        "pointincircle": {
          "id": "14104461-269c-4d73-9345-563438d9e46a",
          "input": {
            "rad": { "metadata": { "position": { "x": -179, "y": -58 } } },
            "x": {
              "metadata": { "position": { "x": -177, "y": -60 } },
              "constant": false
            },
            "y": { "metadata": { "position": { "x": -156, "y": -82 } } },
            "r": { "metadata": { "position": { "x": -180, "y": -32 } } }
          },
          "output": {
            "x": {
              "metadata": {
                "position": { "x": 155.8133171179178, "y": -654.0221456420934 }
              }
            },
            "y": {
              "metadata": {
                "position": { "x": 141.42873948509634, "y": -623.7966988778626 }
              }
            }
          },
          "metadata": { "position": { "x": -122, "y": -62 } }
        }
      },
      "merges": {
        "0": {
          "pointincircle": { "output": { "x": true } },
          "makepie": { "input": { "x0": true } }
        },
        "1": {
          "pointincircle": { "output": { "y": true } },
          "makepie": { "input": { "y0": true } }
        },
        "2": {
          "pointincircle0": { "output": { "x": true } },
          "makepie": { "input": { "x1": true } }
        },
        "3": {
          "pointincircle0": { "output": { "y": true } },
          "makepie": { "input": { "y1": true } }
        },
        "4": {
          "pointincircle1": { "output": { "x": true } },
          "makepie": { "input": { "x2": true } }
        },
        "5": {
          "pointincircle1": { "output": { "y": true } },
          "makepie": { "input": { "y2": true } }
        },
        "6": {
          "pointincircle2": { "output": { "x": true } },
          "makepie": { "input": { "x3": true } }
        },
        "7": {
          "pointincircle2": { "output": { "y": true } },
          "makepie": { "input": { "y3": true } }
        },
        "8": {
          "degtorad0": { "input": { "angle": true } },
          "pieangles": { "output": { "a0": true } }
        },
        "9": {
          "degtorad1": { "input": { "angle": true } },
          "pieangles": { "output": { "a1": true } }
        },
        "10": {
          "degtorad0": { "output": { "rad": true } },
          "pointincircle0": { "input": { "rad": true } },
          "pointincircle": { "input": { "rad": true } }
        },
        "12": {
          "degtorad1": { "output": { "rad": true } },
          "pointincircle1": { "input": { "rad": true } },
          "pointincircle2": { "input": { "rad": true } }
        }
      },
      "inputs": {
        "y": {
          "plug": {
            "0": { "unitId": "pointincircle2", "pinId": "y" },
            "1": { "unitId": "pointincircle0", "pinId": "y" },
            "3": { "unitId": "pointincircle", "pinId": "y" },
            "4": { "unitId": "pointincircle1", "pinId": "y" }
          },
          "ref": false
        },
        "x": {
          "plug": {
            "0": { "unitId": "pointincircle1", "pinId": "x" },
            "2": { "unitId": "pointincircle2", "pinId": "x" },
            "3": { "unitId": "pointincircle0", "pinId": "x" },
            "4": { "unitId": "pointincircle", "pinId": "x" }
          },
          "ref": false
        },
        "n": {
          "plug": { "0": { "unitId": "pieangles", "pinId": "n" } },
          "ref": false
        },
        "i": {
          "plug": { "0": { "unitId": "pieangles", "pinId": "i" } },
          "ref": false
        },
        "R": {
          "plug": {
            "0": { "unitId": "pointincircle1", "pinId": "r" },
            "1": { "unitId": "makepie", "pinId": "R" },
            "2": { "unitId": "pointincircle0", "pinId": "r" }
          },
          "ref": false
        },
        "r": {
          "plug": {
            "0": { "unitId": "makepie", "pinId": "r" },
            "1": { "unitId": "pointincircle2", "pinId": "r" },
            "2": { "unitId": "pointincircle", "pinId": "r" }
          },
          "ref": false
        }
      },
      "outputs": {
        "obj": {
          "plug": { "0": { "unitId": "makepie", "pinId": "obj" } },
          "ref": false
        }
      },
      "metadata": { "icon": "question", "description": "" },
      "id": "8a41cd0c-a2de-409c-a8fe-71d7a7d9a96a"
    },
    "14104461-269c-4d73-9345-563438d9e46a": {
      "name": "point in circle",
      "units": {
        "sin": {
          "id": "b9f90a95-1bfc-4897-9068-2560743821a3",
          "metadata": { "position": { "x": 99, "y": -45 } },
          "input": { "a": {} },
          "output": { "sin(a)": {} }
        },
        "cos": {
          "id": "62af44dc-17d6-459a-b95f-3b8ce5b48856",
          "metadata": { "position": { "x": 4, "y": -67 } },
          "input": { "a": {} },
          "output": { "cos(a)": {} }
        },
        "multiply": {
          "id": "872be100-f4dc-4246-8eea-6e3da31137b7",
          "input": { "b": { "constant": false }, "a": {} },
          "metadata": { "position": { "x": -73, "y": -7 } },
          "output": { "a × b": {} }
        },
        "multiply0": {
          "id": "872be100-f4dc-4246-8eea-6e3da31137b7",
          "input": { "b": { "constant": false }, "a": {} },
          "metadata": { "position": { "x": 31, "y": 29 } },
          "output": { "a × b": {} }
        },
        "add": {
          "id": "6fe452f2-2ec1-4ee2-887d-751c3697e6bf",
          "input": {
            "b": {
              "constant": false,
              "metadata": { "position": { "x": -206, "y": -38 } }
            },
            "a": {}
          },
          "output": {
            "a + b": { "metadata": { "position": { "x": -206, "y": -2 } } }
          },
          "metadata": { "position": { "x": -179, "y": -4 } }
        },
        "subtract": {
          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
          "input": {
            "a": {
              "constant": false,
              "metadata": { "position": { "x": 186, "y": 84 } }
            },
            "b": {}
          },
          "output": {
            "a - b": { "metadata": { "position": { "x": 97, "y": 104 } } }
          },
          "metadata": { "position": { "x": 117, "y": 94 } }
        }
      },
      "merges": {
        "0": {
          "multiply0": { "input": { "a": true } },
          "sin": { "output": { "sin(a)": true } }
        },
        "1": {
          "cos": { "output": { "cos(a)": true } },
          "multiply": { "input": { "a": true } }
        },
        "2": {
          "add": { "input": { "a": true } },
          "multiply": { "output": { "a × b": true } }
        },
        "4": {
          "cos": { "input": { "a": true } },
          "sin": { "input": { "a": true } }
        },
        "6": {
          "multiply0": { "output": { "a × b": true } },
          "subtract": { "input": { "b": true } }
        },
        "7": {
          "multiply0": { "input": { "b": true } },
          "multiply": { "input": { "b": true } }
        }
      },
      "metadata": {
        "icon": null,
        "description": "no description",
        "tags": ["platform", "core", "math", "geometry", "trigonometry"]
      },
      "inputs": {
        "rad": { "plug": { "0": { "mergeId": "4" } }, "type": "number" },
        "x": {
          "plug": { "0": { "unitId": "add", "pinId": "b" } },
          "functional": true,
          "type": "number"
        },
        "y": {
          "plug": { "0": { "unitId": "subtract", "pinId": "a" } },
          "functional": true,
          "type": "number"
        },
        "r": { "plug": { "0": { "mergeId": "7" } }, "type": "number" }
      },
      "outputs": {
        "x": {
          "plug": { "0": { "unitId": "add", "pinId": "a + b" } },
          "type": "number",
          "ref": false
        },
        "y": {
          "plug": { "0": { "unitId": "subtract", "pinId": "a - b" } },
          "type": "number",
          "ref": false
        }
      },
      "id": "14104461-269c-4d73-9345-563438d9e46a",
      "type": "`U`&`G`"
    },
    "1adde6cb-0840-463d-91a4-01a255a72ab6": {
      "type": "`U`&`G`&`C`",
      "name": "pie angles",
      "units": {
        "divide": {
          "id": "158d85eb-28b8-4355-b637-28a7ec751233",
          "input": {
            "a": {
              "constant": true,
              "data": "360",
              "metadata": { "position": { "x": -180, "y": -13 } }
            },
            "b": { "metadata": { "position": { "x": -170, "y": -45 } } }
          },
          "output": { "a ÷ b": {} },
          "metadata": { "position": { "x": -111, "y": -37 } }
        },
        "subtract": {
          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
          "input": {
            "a": {
              "constant": true,
              "data": "180",
              "metadata": { "position": { "x": -161, "y": 63 } }
            },
            "b": {}
          },
          "output": { "a - b": {} },
          "metadata": { "position": { "x": -94, "y": 30 } }
        },
        "half": {
          "id": "49d04556-34fd-4231-a478-0e106b564e1c",
          "input": { "a": {} },
          "output": { "a ÷ 2": {} },
          "metadata": { "position": { "x": -42, "y": 98 } }
        },
        "add": {
          "id": "6fe452f2-2ec1-4ee2-887d-751c3697e6bf",
          "input": { "a": {}, "b": {} },
          "output": { "a + b": {} },
          "metadata": { "position": { "x": 30, "y": 41 } }
        },
        "multiply": {
          "id": "872be100-f4dc-4246-8eea-6e3da31137b7",
          "input": {
            "a": {},
            "b": { "metadata": { "position": { "x": -70, "y": -61 } } }
          },
          "output": { "a × b": {} },
          "metadata": { "position": { "x": -23, "y": -43 } }
        },
        "add0": {
          "id": "6fe452f2-2ec1-4ee2-887d-751c3697e6bf",
          "input": { "a": {}, "b": {} },
          "output": {
            "a + b": { "metadata": { "position": { "x": 139, "y": -71 } } }
          },
          "metadata": { "position": { "x": 18, "y": -23 } }
        },
        "subtract0": {
          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
          "input": {
            "a": {
              "constant": true,
              "data": "360",
              "metadata": { "position": { "x": 117, "y": -109 } }
            },
            "b": {}
          },
          "output": {
            "a - b": { "metadata": { "position": { "x": 134, "y": -78 } } }
          },
          "metadata": { "position": { "x": 100, "y": -97 } }
        },
        "subtract1": {
          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
          "input": {
            "a": {
              "ignored": false,
              "constant": true,
              "data": "360",
              "metadata": { "position": { "x": 156, "y": 43 } }
            },
            "b": { "ignored": false, "constant": false }
          },
          "output": {
            "a - b": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": 145, "y": 75 } }
            }
          },
          "metadata": { "position": { "x": 122, "y": 30 } }
        }
      },
      "merges": {
        "0": {
          "divide": { "output": { "a ÷ b": true } },
          "subtract": { "input": { "b": true } },
          "multiply": { "input": { "a": true } },
          "add0": { "input": { "b": true } }
        },
        "1": {
          "half": { "input": { "a": true } },
          "subtract": { "output": { "a - b": true } }
        },
        "2": {
          "add": { "input": { "a": true } },
          "half": { "output": { "a ÷ 2": true } }
        },
        "3": {
          "add": { "input": { "b": true } },
          "multiply": { "output": { "a × b": true } }
        },
        "5": {
          "add0": { "input": { "a": true } },
          "add": { "output": { "a + b": true } },
          "subtract1": { "input": { "b": true } }
        },
        "7": {
          "subtract0": { "input": { "b": true } },
          "add0": { "output": { "a + b": true } }
        }
      },
      "inputs": {
        "n": {
          "plug": { "0": { "unitId": "divide", "pinId": "b" } },
          "functional": true,
          "ref": false
        },
        "i": {
          "plug": { "0": { "unitId": "multiply", "pinId": "b" } },
          "ref": false,
          "functional": true
        }
      },
      "outputs": {
        "a0": {
          "plug": { "0": { "unitId": "subtract1", "pinId": "a - b" } },
          "ref": false
        },
        "a1": {
          "plug": { "0": { "unitId": "subtract0", "pinId": "a - b" } },
          "ref": false
        }
      },
      "metadata": { "icon": "question", "description": "" },
      "id": "1adde6cb-0840-463d-91a4-01a255a72ab6",
      "component": {}
    },
    "0130c7af-e013-4e0f-a20c-276878069764": {
      "type": "`U`&`G`&`C`",
      "name": "make pie",
      "units": {
        "set": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": {
              "ignored": false,
              "constant": true,
              "data": "{}",
              "metadata": { "position": { "x": 178, "y": 187 } }
            },
            "key": {
              "ignored": false,
              "constant": true,
              "data": "\"x0\"",
              "metadata": { "position": { "x": 95, "y": 183 } }
            },
            "value": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": 87, "y": 152 } }
            }
          },
          "output": { "obj": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 135, "y": 157 } }
        },
        "set0": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": { "ignored": false, "constant": false },
            "key": {
              "ignored": false,
              "constant": true,
              "data": "\"y0\"",
              "metadata": { "position": { "x": 273, "y": 107 } }
            },
            "value": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": 274, "y": 75 } }
            }
          },
          "output": { "obj": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 222, "y": 87 } }
        },
        "set1": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": { "ignored": false, "constant": false },
            "key": {
              "ignored": false,
              "constant": true,
              "data": "\"x1\"",
              "metadata": { "position": { "x": 101, "y": 25 } }
            },
            "value": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": 101, "y": -3 } }
            }
          },
          "output": { "obj": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 146, "y": 7 } }
        },
        "set2": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": { "ignored": false, "constant": false },
            "key": {
              "ignored": false,
              "constant": true,
              "data": "\"y1\"",
              "metadata": { "position": { "x": 255, "y": -71 } }
            },
            "value": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": 251, "y": -103 } }
            }
          },
          "output": { "obj": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 202, "y": -85 } }
        },
        "set4": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": { "ignored": false, "constant": false },
            "key": {
              "ignored": false,
              "constant": true,
              "data": "\"x3\"",
              "metadata": { "position": { "x": -138, "y": -61 } }
            },
            "value": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": -39, "y": -37 } }
            }
          },
          "output": { "obj": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": -90, "y": -52 } }
        },
        "set5": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": { "ignored": false, "constant": false },
            "key": {
              "ignored": false,
              "constant": true,
              "data": "\"y2\"",
              "metadata": { "position": { "x": -55, "y": -140 } }
            },
            "value": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": -28, "y": -168 } }
            }
          },
          "output": { "obj": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": -9, "y": -130 } }
        },
        "set6": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": { "ignored": false, "constant": false },
            "key": {
              "ignored": false,
              "constant": true,
              "data": "\"y3\"",
              "metadata": { "position": { "x": -89, "y": 83 } }
            },
            "value": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": -73, "y": 53 } }
            }
          },
          "output": { "obj": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": -128, "y": 47 } }
        },
        "set3": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": { "ignored": false, "constant": false },
            "key": {
              "ignored": false,
              "constant": true,
              "data": "\"x2\"",
              "metadata": { "position": { "x": 80, "y": -177 } }
            },
            "value": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": 149, "y": -168 } }
            }
          },
          "output": { "obj": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 105, "y": -144 } }
        },
        "set7": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": { "ignored": false, "constant": false },
            "key": {
              "ignored": false,
              "constant": true,
              "data": "\"R\"",
              "metadata": { "position": { "x": -200, "y": 111 } }
            },
            "value": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": -271, "y": 109 } }
            }
          },
          "output": { "obj": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": -238, "y": 76 } }
        },
        "set8": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": { "ignored": false, "constant": false },
            "key": {
              "ignored": false,
              "constant": true,
              "data": "\"r\"",
              "metadata": { "position": { "x": -389, "y": 56 } }
            },
            "value": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": -388, "y": 21 } }
            }
          },
          "output": {
            "obj": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": -309, "y": 6 } }
            }
          },
          "metadata": { "position": { "x": -345, "y": 37 } }
        }
      },
      "merges": {
        "0": {
          "set8": { "input": { "obj": true } },
          "set7": { "output": { "obj": true } }
        },
        "1": {
          "set7": { "input": { "obj": true } },
          "set6": { "output": { "obj": true } }
        },
        "5": {
          "set0": { "input": { "obj": true } },
          "set": { "output": { "obj": true } }
        },
        "6": {
          "set1": { "input": { "obj": true } },
          "set0": { "output": { "obj": true } }
        },
        "7": {
          "set2": { "input": { "obj": true } },
          "set1": { "output": { "obj": true } }
        },
        "8": {
          "set3": { "input": { "obj": true } },
          "set2": { "output": { "obj": true } }
        },
        "9": {
          "set5": { "input": { "obj": true } },
          "set3": { "output": { "obj": true } }
        },
        "10": {
          "set4": { "input": { "obj": true } },
          "set5": { "output": { "obj": true } }
        },
        "11": {
          "set6": { "input": { "obj": true } },
          "set4": { "output": { "obj": true } }
        }
      },
      "inputs": {
        "y3": {
          "plug": { "0": { "unitId": "set6", "pinId": "value" } },
          "functional": true
        },
        "x0": {
          "plug": { "0": { "unitId": "set", "pinId": "value" } },
          "functional": true
        },
        "x3": {
          "plug": { "0": { "unitId": "set4", "pinId": "value" } },
          "functional": true
        },
        "y1": {
          "plug": { "0": { "unitId": "set2", "pinId": "value" } },
          "functional": true
        },
        "y2": {
          "plug": { "0": { "unitId": "set5", "pinId": "value" } },
          "functional": true
        },
        "x1": {
          "plug": { "0": { "unitId": "set1", "pinId": "value" } },
          "functional": true
        },
        "x2": {
          "plug": { "0": { "unitId": "set3", "pinId": "value" } },
          "functional": true
        },
        "y0": {
          "plug": { "0": { "unitId": "set0", "pinId": "value" } },
          "functional": true
        },
        "r": {
          "plug": { "0": { "unitId": "set8", "pinId": "value" } },
          "type": "any",
          "functional": true
        },
        "R": {
          "plug": { "0": { "unitId": "set7", "pinId": "value" } },
          "type": "any",
          "ref": false
        }
      },
      "outputs": {
        "obj": {
          "plug": { "0": { "unitId": "set8", "pinId": "obj" } },
          "type": "object"
        }
      },
      "metadata": { "icon": null, "description": "" },
      "id": "0130c7af-e013-4e0f-a20c-276878069764"
    },
    "6f36579a-24b3-40f4-bd85-dcb9ffff65f9": {
      "type": "`U`&`G`&`C`",
      "name": "pie icon",
      "units": {
        "mergedefault0": {
          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
          "input": {
            "a": { "metadata": { "position": { "x": 222, "y": 4 } } },
            "default": {
              "constant": true,
              "data": "{\"width\":\"15%\",\"height\":\"15%\",\"position\":\"absolute\",\"strokeWidth\":\"2px\",\"zIndex\":\"3\",\"pointerEvents\":\"none\"}",
              "metadata": { "position": { "x": 222, "y": 41 } }
            }
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": 156, "y": 16 } }
        },
        "icon": {
          "id": "63a417e5-d354-4b39-9ebd-05f55e70de7b",
          "input": {
            "style": {},
            "icon": { "metadata": { "position": { "x": -80, "y": 51 } } },
            "attr": { "ignored": false }
          },
          "output": {},
          "metadata": {
            "position": { "x": 0, "y": 16 },
            "component": { "width": 140.59765625, "height": 166.62109375 }
          }
        },
        "mergedefault": {
          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
          "input": {
            "a": { "metadata": { "position": { "x": -102, "y": -55 } } },
            "default": {
              "constant": true,
              "data": "{\"width\":\"30\",\"height\":\"30px\"}",
              "metadata": { "position": { "x": -186, "y": -26 } }
            }
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": -156, "y": -31 } }
        }
      },
      "merges": {
        "0": {
          "mergedefault": { "output": { "a": true } },
          "icon": { "input": { "attr": true } }
        },
        "9": {
          "mergedefault0": { "output": { "a": true } },
          "icon": { "input": { "style": true } }
        }
      },
      "inputs": {
        "icon": {
          "plug": { "0": { "unitId": "icon", "pinId": "icon" } },
          "ref": false
        },
        "style": {
          "plug": { "0": { "unitId": "mergedefault0", "pinId": "a" } },
          "ref": false
        },
        "attr": {
          "plug": {
            "0": { "unitId": "mergedefault", "pinId": "a", "kind": "input" }
          },
          "ref": false
        }
      },
      "outputs": {},
      "metadata": { "icon": "question", "description": "" },
      "render": true,
      "component": {
        "subComponents": {
          "icon": { "children": [], "childSlot": {}, "width": 45, "height": 45 }
        },
        "children": ["icon"],
        "defaultWidth": 45,
        "defaultHeight": 44.87109375
      },
      "id": "6f36579a-24b3-40f4-bd85-dcb9ffff65f9"
    },
    "0a5bd454-2b17-48b8-b39e-8f7cb6782986": {
      "type": "`U`&`G`&`C`",
      "name": "get icon position",
      "units": {
        "degtorad": {
          "id": "3a138358-cc25-45e3-a0ce-bbe54445736f",
          "input": { "angle": {} },
          "output": { "rad": {} },
          "metadata": { "position": { "x": -59, "y": -8 } }
        },
        "average": {
          "id": "635a946a-b11e-450e-99a8-5cbe8928cb0c",
          "output": { "ab2": {} },
          "metadata": { "position": { "x": -164, "y": -7 } },
          "input": { "a": {}, "b": {} }
        },
        "pointincircle": {
          "id": "14104461-269c-4d73-9345-563438d9e46a",
          "input": {
            "rad": {},
            "x": { "metadata": { "position": { "x": 0, "y": -38 } } },
            "y": { "metadata": { "position": { "x": 10, "y": 29 } } },
            "r": {}
          },
          "output": {
            "x": { "metadata": { "position": { "x": 63, "y": -48 } } },
            "y": { "metadata": { "position": { "x": 86, "y": -22 } } }
          },
          "metadata": { "position": { "x": 54, "y": -15 } }
        },
        "pieangles": {
          "id": "1adde6cb-0840-463d-91a4-01a255a72ab6",
          "input": {
            "n": { "metadata": { "position": { "x": -324, "y": -18 } } },
            "i": { "metadata": { "position": { "x": -324, "y": 17 } } }
          },
          "output": { "a0": {}, "a1": {} },
          "metadata": { "position": { "x": -261, "y": -7 } }
        },
        "average0": {
          "id": "635a946a-b11e-450e-99a8-5cbe8928cb0c",
          "input": {
            "a": {
              "constant": false,
              "ignored": false,
              "metadata": { "position": { "x": 300, "y": 35 } }
            },
            "b": {
              "constant": false,
              "ignored": false,
              "metadata": { "position": { "x": 297, "y": -1 } }
            }
          },
          "output": { "ab2": { "constant": false, "ignored": false } },
          "metadata": { "position": { "x": 269, "y": 13 } }
        },
        "add": {
          "id": "6fe452f2-2ec1-4ee2-887d-751c3697e6bf",
          "input": {
            "a": {},
            "b": {
              "constant": true,
              "data": "0",
              "metadata": { "position": { "x": 161, "y": 71 } }
            }
          },
          "output": { "a + b": {} },
          "metadata": { "position": { "x": 161, "y": 23 } }
        }
      },
      "merges": {
        "0": {
          "average0": { "output": { "ab2": true } },
          "add": { "input": { "a": true } }
        },
        "1": {
          "add": { "output": { "a + b": true } },
          "pointincircle": { "input": { "r": true } }
        },
        "9": {
          "degtorad": { "input": { "angle": true } },
          "average": { "output": { "ab2": true } }
        },
        "10": {
          "degtorad": { "output": { "rad": true } },
          "pointincircle": { "input": { "rad": true } }
        },
        "13": {
          "pieangles": { "output": { "a0": true } },
          "average": { "input": { "a": true } }
        },
        "14": {
          "pieangles": { "output": { "a1": true } },
          "average": { "input": { "b": true } }
        }
      },
      "inputs": {
        "x": {
          "plug": { "0": { "unitId": "pointincircle", "pinId": "x" } },
          "ref": false
        },
        "y": {
          "plug": { "0": { "unitId": "pointincircle", "pinId": "y" } },
          "ref": false
        },
        "n": {
          "plug": { "0": { "unitId": "pieangles", "pinId": "n" } },
          "ref": false
        },
        "i": {
          "plug": { "0": { "unitId": "pieangles", "pinId": "i" } },
          "ref": false
        },
        "r": {
          "plug": { "0": { "unitId": "average0", "pinId": "a" } },
          "ref": false
        },
        "R": {
          "plug": { "0": { "unitId": "average0", "pinId": "b" } },
          "ref": false
        }
      },
      "outputs": {
        "x": {
          "plug": { "0": { "unitId": "pointincircle", "pinId": "x" } },
          "ref": false
        },
        "y": {
          "plug": { "0": { "unitId": "pointincircle", "pinId": "y" } },
          "ref": false
        }
      },
      "metadata": { "icon": "question", "description": "" },
      "id": "0a5bd454-2b17-48b8-b39e-8f7cb6782986",
      "component": {}
    },
    "635a946a-b11e-450e-99a8-5cbe8928cb0c": {
      "type": "`U`&`G`&`C`",
      "name": "average",
      "units": {
        "half": {
          "id": "49d04556-34fd-4231-a478-0e106b564e1c",
          "input": { "a": {} },
          "output": { "a ÷ 2": {} }
        },
        "add": {
          "id": "6fe452f2-2ec1-4ee2-887d-751c3697e6bf",
          "input": { "a": {}, "b": {} },
          "output": { "a + b": {} }
        }
      },
      "merges": {
        "9": {
          "add": { "output": { "a + b": true } },
          "half": { "input": { "a": true } }
        }
      },
      "inputs": {
        "a": {
          "plug": { "0": { "unitId": "add", "pinId": "a" } },
          "ref": false
        },
        "b": {
          "plug": { "0": { "unitId": "add", "pinId": "b" } },
          "ref": false
        }
      },
      "outputs": {
        "ab2": {
          "plug": { "0": { "unitId": "half", "pinId": "a ÷ 2" } },
          "ref": false
        }
      },
      "metadata": { "icon": "question", "description": "" },
      "id": "635a946a-b11e-450e-99a8-5cbe8928cb0c"
    },
    "8b5af101-cb7f-4a87-b461-b6ce15ec88c5": {
      "type": "`U`&`G`&`C`",
      "name": "pie slice",
      "units": {
        "replaceobj": {
          "id": "d222eb61-6d6d-467b-b82e-449d46b339b3",
          "input": {
            "str": {
              "constant": true,
              "data": "\"M x0 y0 L x1 y1 A R R 1 0 1 x2 y2 L x3 y3 A r r 1 0 0 x0 y0 z\"",
              "metadata": { "position": { "x": 349, "y": -213 } }
            },
            "obj": {
              "metadata": {
                "position": { "x": 240.16663705069618, "y": 292.5034882068733 }
              },
              "constant": false
            }
          },
          "output": {
            "str": {
              "metadata": {
                "position": { "x": 335.9109233539747, "y": 273.9188943479159 }
              }
            }
          },
          "metadata": { "position": { "x": 310, "y": -235 } }
        },
        "makepieobj": {
          "id": "8a41cd0c-a2de-409c-a8fe-71d7a7d9a96a",
          "output": {
            "obj": {
              "metadata": {
                "position": { "x": -234.5201399096004, "y": 355.69273127470234 }
              }
            }
          },
          "metadata": { "position": { "x": 434, "y": -258 } },
          "input": {
            "y": {
              "metadata": { "position": { "x": 559, "y": -221 } },
              "constant": false
            },
            "x": {
              "metadata": { "position": { "x": 565, "y": -192 } },
              "constant": false
            },
            "n": {
              "metadata": { "position": { "x": 471, "y": -229 } },
              "constant": false
            },
            "i": {
              "metadata": { "position": { "x": 548, "y": -165 } },
              "constant": false
            },
            "R": {
              "metadata": { "position": { "x": 529, "y": -244 } },
              "constant": false
            },
            "r": {
              "metadata": { "position": { "x": 484, "y": -160 } },
              "constant": false
            }
          }
        },
        "onpointerenter": {
          "id": "c0bb493a-af78-11ea-b6fa-3b893b757a39",
          "input": { "element": {} },
          "output": { "event": {} },
          "metadata": { "position": { "x": -16, "y": -91 } }
        },
        "onpointerleave": {
          "id": "c7dba94e-af78-11ea-b7d7-47e14ca215b5",
          "input": { "element": {} },
          "output": { "event": {} },
          "metadata": { "position": { "x": 252, "y": -92 } }
        },
        "mergedefault": {
          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
          "input": {
            "a": { "metadata": { "position": { "x": -378, "y": -234 } } },
            "default": {}
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": -252, "y": -28 } }
        },
        "true": {
          "id": "984c4a44-86c7-464c-ad48-4759598c3be3",
          "input": { "any": {} },
          "output": { "true": {} },
          "metadata": { "position": { "x": 59, "y": -36 } }
        },
        "false": {
          "id": "b4d20f6f-32dc-4d04-97d6-69518976bdea",
          "input": { "any": {} },
          "output": { "false": {} },
          "metadata": { "position": { "x": 168, "y": -31 } }
        },
        "iterate": {
          "id": "8a2b756a-25e4-11eb-860d-1f34c850b992",
          "input": {
            "next": {},
            "init": {
              "constant": true,
              "data": "false",
              "metadata": { "position": { "x": -104, "y": -110 } }
            }
          },
          "output": {
            "current": {},
            "local": {
              "ignored": true,
              "metadata": { "position": { "x": -166, "y": -164 } }
            }
          },
          "metadata": { "position": { "x": 56, "y": 25 } }
        },
        "pick0": {
          "id": "396497e4-f8e7-4573-95c9-a4f186a5cd05",
          "input": {
            "a": {
              "constant": true,
              "data": "{\"color\":\"#26bbf8\",\"zIndex\":\"1\"}",
              "metadata": { "position": { "x": -218, "y": -148 } }
            },
            "b": {
              "constant": true,
              "data": "{\"color\":\"currentcolor\",\"zIndex\":\"0\"}",
              "metadata": { "position": { "x": -288, "y": -148 } }
            },
            "c": {}
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": -46, "y": 79 } }
        },
        "pieicon": {
          "id": "6f36579a-24b3-40f4-bd85-dcb9ffff65f9",
          "input": {
            "style": { "metadata": { "position": { "x": 64, "y": 283 } } },
            "icon": { "metadata": { "position": { "x": 231, "y": 316 } } },
            "attr": {}
          },
          "metadata": {
            "position": { "x": 260, "y": 522 },
            "component": { "width": 105.47265625, "height": 177.48828125 }
          },
          "output": {}
        },
        "geticonposition": {
          "id": "0a5bd454-2b17-48b8-b39e-8f7cb6782986",
          "input": {
            "x": { "metadata": { "position": { "x": -229, "y": 472 } } },
            "y": { "metadata": { "position": { "x": -180, "y": 461 } } },
            "n": { "metadata": { "position": { "x": -260, "y": 453 } } },
            "i": { "metadata": { "position": { "x": -169, "y": 410 } } },
            "r": { "metadata": { "position": { "x": -271, "y": 428 } } },
            "R": { "metadata": { "position": { "x": -261, "y": 403 } } }
          },
          "metadata": { "position": { "x": -114, "y": 522 } },
          "output": { "x": {}, "y": {} }
        },
        "set": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": { "constant": false },
            "key": {
              "constant": true,
              "data": "\"y\"",
              "metadata": { "position": { "x": 40, "y": 377 } }
            },
            "value": {}
          },
          "output": {
            "obj": { "metadata": { "position": { "x": 98, "y": 29 } } }
          },
          "metadata": { "position": { "x": 111, "y": 519 } }
        },
        "set0": {
          "id": "c4784b05-1f50-44ad-b812-545b3ed77614",
          "input": {
            "obj": {
              "constant": true,
              "data": "{}",
              "metadata": { "position": { "x": -130, "y": 255 } }
            },
            "key": {
              "constant": true,
              "data": "\"x\"",
              "metadata": { "position": { "x": -57, "y": 258 } }
            },
            "value": {}
          },
          "output": { "obj": {} },
          "metadata": { "position": { "x": 67, "y": 419 } }
        },
        "pick": {
          "id": "396497e4-f8e7-4573-95c9-a4f186a5cd05",
          "input": {
            "a": {
              "constant": true,
              "data": "{\"fill\":\"#1f1f1fdd\"}",
              "metadata": { "position": { "x": -210, "y": 1 } }
            },
            "b": {
              "constant": true,
              "data": "{\"fill\":\"#1f1f1faa\"}",
              "metadata": { "position": { "x": -232, "y": -27 } }
            },
            "c": {}
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": 75, "y": 91 } }
        },
        "mergedefault0": {
          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
          "input": {
            "a": {},
            "default": {
              "constant": true,
              "metadata": { "position": { "x": -418, "y": -128 } },
              "data": "{\"position\":\"absolute\",\"left\":\"0\",\"top\":\"0\",\"pointerEvents\":\"none\",\"width\":\"100%\",\"height\":\"100%\"}"
            }
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": -159, "y": 35 } }
        },
        "path": {
          "id": "60bc7930-8982-11ea-8b24-cf97a783bb32",
          "input": {
            "style": {
              "metadata": { "position": { "x": 152, "y": 26 } },
              "ignored": false,
              "constant": false
            },
            "d": { "metadata": { "position": { "x": 152, "y": 64 } } },
            "fillRule": {
              "ignored": true,
              "metadata": { "position": { "x": 333, "y": -130 } }
            }
          },
          "output": {},
          "metadata": { "position": { "x": 117, "y": -176 } }
        },
        "svg": {
          "id": "98c279fc-7f5e-11ea-b9e4-f772f9f0bed1",
          "input": {
            "style": { "metadata": { "position": { "x": -322, "y": -13 } } },
            "viewBox": {
              "ignored": false,
              "metadata": { "position": { "x": -405, "y": -370 } },
              "constant": true,
              "data": "\"0 0 200 200\""
            }
          },
          "output": {},
          "metadata": { "position": { "x": -402, "y": -193 } }
        },
        "mergedefault2": {
          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
          "input": {
            "a": {},
            "default": {
              "constant": true,
              "data": "{\"cursor\":\"pointer\",\"fill\":\"#000000aa\",\"strokeWidth\":\"2px\",\"pointerEvents\":\"auto\"}",
              "metadata": { "position": { "x": -39, "y": 29 } }
            }
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": 158, "y": 15 } }
        },
        "subtract": {
          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
          "input": {
            "a": {},
            "b": {
              "constant": true,
              "data": "15",
              "metadata": { "position": { "x": -250, "y": 299 } }
            }
          },
          "output": { "a - b": {} },
          "metadata": { "position": { "x": -44, "y": 433 } }
        },
        "subtract0": {
          "id": "fa94b179-00e3-4ed1-814e-7938324a833f",
          "input": {
            "a": { "ignored": false, "constant": false },
            "b": {
              "ignored": false,
              "constant": true,
              "data": "15",
              "metadata": { "position": { "x": -50, "y": 451 } }
            }
          },
          "output": { "a - b": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 4, "y": 559 } }
        },
        "onclick": {
          "id": "97c94516-add1-11ea-ba72-8f55299b735c",
          "input": { "element": {} },
          "output": { "event": {} },
          "metadata": { "position": { "x": -260, "y": -148 } }
        },
        "dispatch": {
          "id": "a1cd3c3c-b1fa-40a2-80d1-32f416264a3d",
          "input": {
            "element": {},
            "event": {
              "constant": true,
              "data": "\"click\"",
              "metadata": { "position": { "x": 117, "y": -284 } }
            },
            "data": {},
            "opt": {
              "constant": true,
              "data": "{}",
              "metadata": { "position": { "x": 100, "y": -310 } }
            }
          },
          "output": {},
          "metadata": { "position": { "x": -39, "y": -137 } }
        },
        "wait": {
          "id": "ba38b0af-80c0-49e4-9e39-864396964ccc",
          "input": {
            "a": {
              "constant": true,
              "metadata": { "position": { "x": 9, "y": -366 } }
            },
            "b": {}
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": -158, "y": -124 } }
        },
        "stoppropagation": {
          "id": "d0f94e4e-43f4-4c0a-b07e-ca40c82f48f4",
          "input": {
            "component": {},
            "name": { "constant": true, "data": "\"click\"" },
            "done": { "ignored": true }
          },
          "output": {},
          "metadata": { "position": { "x": 261, "y": -158 } }
        },
        "onpointerenter0": {
          "id": "c0bb493a-af78-11ea-b6fa-3b893b757a39",
          "input": { "element": {} },
          "output": { "event": {} },
          "metadata": { "position": { "x": -255, "y": -275 } }
        },
        "wait0": {
          "id": "ba38b0af-80c0-49e4-9e39-864396964ccc",
          "input": {
            "a": {
              "constant": true,
              "metadata": { "position": { "x": 9, "y": -366 } }
            },
            "b": {}
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": -144, "y": -325 } }
        },
        "dispatch0": {
          "id": "a1cd3c3c-b1fa-40a2-80d1-32f416264a3d",
          "input": {
            "element": {},
            "event": {
              "constant": true,
              "data": "\"enter\"",
              "metadata": { "position": { "x": 117, "y": -284 } }
            },
            "data": {},
            "opt": {
              "constant": true,
              "data": "{}",
              "metadata": { "position": { "x": 100, "y": -310 } }
            }
          },
          "output": {},
          "metadata": { "position": { "x": -24, "y": -295 } }
        },
        "onpointerleave0": {
          "id": "c7dba94e-af78-11ea-b7d7-47e14ca215b5",
          "input": { "element": {} },
          "output": { "event": {} },
          "metadata": { "position": { "x": -254, "y": -202 } }
        },
        "wait1": {
          "id": "ba38b0af-80c0-49e4-9e39-864396964ccc",
          "input": {
            "a": {
              "constant": true,
              "metadata": { "position": { "x": 9, "y": -366 } }
            },
            "b": {}
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": -147, "y": -217 } }
        },
        "dispatch1": {
          "id": "a1cd3c3c-b1fa-40a2-80d1-32f416264a3d",
          "input": {
            "element": {},
            "event": {
              "constant": true,
              "data": "\"leave\"",
              "metadata": { "position": { "x": 117, "y": -284 } }
            },
            "data": {},
            "opt": {
              "constant": true,
              "data": "{}",
              "metadata": { "position": { "x": 100, "y": -310 } }
            }
          },
          "output": {},
          "metadata": { "position": { "x": -32, "y": -212 } }
        }
      },
      "merges": {
        "0": {
          "mergedefault": { "output": { "a": true } },
          "svg": { "input": { "style": true } }
        },
        "1": {
          "replaceobj": { "output": { "str": true } },
          "path": { "input": { "d": true } }
        },
        "2": {
          "replaceobj": { "input": { "obj": true } },
          "makepieobj": { "output": { "obj": true } }
        },
        "3": {
          "onpointerleave": { "output": { "event": true } },
          "false": { "input": { "any": true } }
        },
        "4": {
          "onpointerenter": { "output": { "event": true } },
          "true": { "input": { "any": true } }
        },
        "5": {
          "true": { "output": { "true": true } },
          "false": { "output": { "false": true } },
          "iterate": { "input": { "next": true } }
        },
        "6": {
          "onpointerenter": { "input": { "element": true } },
          "onpointerleave": { "input": { "element": true } },
          "path": { "output": { "_self": true } },
          "dispatch": { "input": { "element": true } },
          "stoppropagation": { "input": { "component": true } },
          "dispatch1": { "input": { "element": true } },
          "dispatch0": { "input": { "element": true } }
        },
        "7": {
          "pick0": { "input": { "c": true } },
          "iterate": { "output": { "current": true } },
          "pick": { "input": { "c": true } }
        },
        "8": {
          "mergedefault0": { "output": { "a": true } },
          "mergedefault": { "input": { "default": true } }
        },
        "9": {
          "subtract": { "input": { "a": true } },
          "geticonposition": { "output": { "x": true } }
        },
        "10": {
          "geticonposition": { "output": { "y": true } },
          "subtract0": { "input": { "a": true } }
        },
        "11": {
          "set": { "output": { "obj": true } },
          "pieicon": { "input": { "attr": true } }
        },
        "12": {
          "set": { "input": { "obj": true } },
          "set0": { "output": { "obj": true } }
        },
        "13": {
          "subtract0": { "output": { "a - b": true } },
          "set": { "input": { "value": true } }
        },
        "14": {
          "mergedefault2": { "output": { "a": true } },
          "path": { "input": { "style": true } }
        },
        "15": {
          "pick": { "output": { "a": true } },
          "mergedefault2": { "input": { "a": true } }
        },
        "16": {
          "wait0": { "input": { "b": true } },
          "onpointerenter0": { "output": { "event": true } }
        },
        "17": {
          "subtract": { "output": { "a - b": true } },
          "set0": { "input": { "value": true } }
        },
        "18": {
          "wait": { "input": { "b": true } },
          "onclick": { "output": { "event": true } }
        },
        "19": {
          "wait": { "output": { "a": true } },
          "dispatch": { "input": { "data": true } }
        },
        "20": {
          "mergedefault0": { "input": { "a": true } },
          "pick0": { "output": { "a": true } }
        },
        "21": {
          "onclick": { "input": { "element": true } },
          "svg": { "output": { "_self": true } },
          "onpointerenter0": { "input": { "element": true } },
          "onpointerleave0": { "input": { "element": true } }
        },
        "22": {
          "wait1": { "input": { "b": true } },
          "onpointerleave0": { "output": { "event": true } }
        },
        "23": {
          "dispatch1": { "input": { "data": true } },
          "wait1": { "output": { "a": true } }
        },
        "24": {
          "dispatch0": { "input": { "data": true } },
          "wait0": { "output": { "a": true } }
        }
      },
      "inputs": {
        "x": {
          "plug": {
            "0": { "unitId": "makepieobj", "pinId": "x" },
            "1": { "unitId": "geticonposition", "pinId": "x" }
          },
          "ref": false
        },
        "n": {
          "plug": {
            "0": { "unitId": "makepieobj", "pinId": "n" },
            "1": { "unitId": "geticonposition", "pinId": "n" }
          },
          "ref": false
        },
        "r": {
          "plug": {
            "0": { "unitId": "makepieobj", "pinId": "r" },
            "1": { "unitId": "geticonposition", "pinId": "r" }
          },
          "ref": false
        },
        "R": {
          "plug": {
            "0": { "unitId": "makepieobj", "pinId": "R" },
            "1": { "unitId": "geticonposition", "pinId": "R" }
          },
          "ref": false
        },
        "i": {
          "plug": {
            "0": { "unitId": "makepieobj", "pinId": "i" },
            "1": { "unitId": "geticonposition", "pinId": "i" }
          },
          "ref": false
        },
        "y": {
          "plug": {
            "0": { "unitId": "makepieobj", "pinId": "y" },
            "1": { "unitId": "geticonposition", "pinId": "y" }
          },
          "ref": false
        },
        "style": {
          "plug": { "0": { "unitId": "mergedefault", "pinId": "a" } },
          "ref": false
        },
        "icon": {
          "plug": {
            "0": { "unitId": "pieicon", "pinId": "icon" },
            "1": { "unitId": "wait1", "pinId": "a", "kind": "input" },
            "2": { "unitId": "wait0", "pinId": "a", "kind": "input" },
            "3": { "unitId": "wait", "pinId": "a", "kind": "input" }
          },
          "type": "string",
          "ref": false
        },
        "value": { "plug": { "0": {} }, "type": "any" }
      },
      "outputs": {},
      "metadata": { "icon": "question", "description": "", "complexity": 129 },
      "render": true,
      "component": {
        "subComponents": {
          "pieicon": {},
          "path": {},
          "svg": { "children": ["path", "pieicon"] }
        },
        "children": ["svg"],
        "defaultWidth": 110.77777777777777,
        "defaultHeight": 107.77777777777777
      },
      "id": "8b5af101-cb7f-4a87-b461-b6ce15ec88c5"
    },
    "becac68c-b30c-49a7-8894-b200c2f5c5dc": {
      "type": "`U`&`G`&`C`",
      "name": "pie",
      "units": {
        "removeallchildren": {
          "id": "2c7507c4-02bc-11eb-88e5-8bc78086a76f",
          "input": {
            "any": {
              "metadata": {
                "position": { "x": 226.96481254534007, "y": 370.9313007422958 }
              }
            },
            "parent": {}
          },
          "output": {},
          "metadata": { "position": { "x": -320, "y": -48 } }
        },
        "plan": {
          "id": "36329d25-218d-48a5-a06c-1e0525da504f",
          "input": {
            "a": { "metadata": { "position": { "x": -175, "y": -91 } } }
          },
          "output": { "0": {}, "1": {} },
          "metadata": { "position": { "x": -216, "y": -65 } }
        },
        "appendchild": {
          "id": "ee4715e6-f479-11ea-b231-bf95a7f38a99",
          "input": {
            "parent": {},
            "child": {
              "metadata": {
                "position": { "x": 226.96481254533995, "y": 254.46845029741723 }
              }
            }
          },
          "output": {
            "at": {
              "ignored": true,
              "metadata": { "position": { "x": -346, "y": 79 } }
            }
          },
          "metadata": { "position": { "x": -321, "y": 40 } }
        },
        "setinput": {
          "id": "43b43afd-9e7d-44ba-aef7-6e9db4ed8744",
          "input": {
            "unit": {
              "constant": false,
              "metadata": {
                "position": { "x": 406.569230835689, "y": 148.23770851808945 }
              }
            },
            "name": {
              "constant": true,
              "data": { "ref": [], "data": "i" },
              "metadata": { "position": { "x": -263, "y": 105 } }
            },
            "data": {
              "metadata": {
                "position": { "x": 498.2582756137112, "y": 112.11059549583888 }
              }
            }
          },
          "output": {
            "unit": {
              "metadata": {
                "position": { "x": 498.25827561370016, "y": 148.2377085181081 }
              }
            }
          },
          "metadata": { "position": { "x": -220, "y": 82 } }
        },
        "div": {
          "id": "66698a97-15c5-4602-9925-11a1aeb03710",
          "input": {
            "style": { "metadata": { "position": { "x": -132, "y": -176 } } },
            "attr": {
              "ignored": true,
              "metadata": { "position": { "x": -336, "y": -9 } }
            }
          },
          "output": {},
          "metadata": {
            "component": {
              "width": 227.89453125,
              "height": 231.71954345703125
            },
            "position": { "x": -479, "y": -5 }
          }
        },
        "setinput0": {
          "id": "43b43afd-9e7d-44ba-aef7-6e9db4ed8744",
          "input": {
            "unit": {
              "ignored": false,
              "constant": true,
              "metadata": { "position": { "x": 577, "y": 114 } }
            },
            "name": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": "n" },
              "metadata": { "position": { "x": 538, "y": 86 } }
            },
            "data": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": 102, "y": 146 } }
            }
          },
          "output": { "unit": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 531, "y": 130 } }
        },
        "foreach0": {
          "id": "e2da2ac1-26bd-4c91-b8ad-a0395d9c8857",
          "input": { "a": {} },
          "output": {
            "a[i]": {},
            "a": {
              "ignored": true,
              "metadata": { "position": { "x": -87, "y": -18 } }
            },
            "i": { "ignored": false },
            "test": {
              "ignored": true,
              "metadata": { "position": { "x": -113, "y": -39 } }
            }
          },
          "metadata": { "position": { "x": -134, "y": 4 } }
        },
        "setinput1": {
          "id": "43b43afd-9e7d-44ba-aef7-6e9db4ed8744",
          "input": {
            "unit": {},
            "name": {
              "constant": true,
              "data": { "ref": [], "data": "icon" },
              "metadata": { "position": { "x": 42, "y": 126 } }
            },
            "data": {}
          },
          "output": { "unit": {} },
          "metadata": { "position": { "x": -6, "y": 137 } }
        },
        "get": {
          "id": "9414421d-3376-4438-aa03-7afcd860972c",
          "input": {
            "obj": {},
            "key": {
              "constant": true,
              "data": { "ref": [], "data": "icon" },
              "metadata": { "position": { "x": 30, "y": 18 } }
            }
          },
          "output": { "value": {} },
          "metadata": { "position": { "x": -18, "y": 33 } }
        },
        "length": {
          "id": "46d98b47-0fa4-409e-bbc6-bb3165d863d8",
          "input": {
            "a": {
              "constant": true,
              "metadata": { "position": { "x": 683, "y": 163 } }
            }
          },
          "output": { "length": {} },
          "metadata": { "position": { "x": 634, "y": 161 } }
        },
        "mergedefault": {
          "id": "304e98ac-bda1-11ea-b416-9746f024148c",
          "input": {
            "a": { "metadata": { "position": { "x": -725, "y": 11 } } },
            "default": {
              "constant": true,
              "data": {
                "ref": [],
                "data": {
                  "position": "relative",
                  "pointerEvents": "none",
                  "height": "100%"
                }
              },
              "metadata": { "position": { "x": -725, "y": -25 } }
            }
          },
          "output": { "a": {} },
          "metadata": { "position": { "x": -680, "y": -6 } }
        },
        "setinput2": {
          "id": "43b43afd-9e7d-44ba-aef7-6e9db4ed8744",
          "input": {
            "unit": { "ignored": false, "constant": false },
            "name": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": "value" },
              "metadata": { "position": { "x": -161, "y": 175 } }
            },
            "data": {
              "ignored": false,
              "constant": false,
              "metadata": { "position": { "x": -66, "y": 122 } }
            }
          },
          "output": { "unit": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": -124, "y": 143 } }
        },
        "get0": {
          "id": "9414421d-3376-4438-aa03-7afcd860972c",
          "input": {
            "obj": { "ignored": false, "constant": false },
            "key": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": "value" },
              "metadata": { "position": { "x": -124, "y": 55 } }
            }
          },
          "output": { "value": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": -78, "y": 61 } }
        },
        "setinput3": {
          "id": "43b43afd-9e7d-44ba-aef7-6e9db4ed8744",
          "input": {
            "unit": { "ignored": false, "constant": false },
            "name": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": "r" },
              "metadata": { "position": { "x": 384, "y": 89 } }
            },
            "data": {
              "ignored": false,
              "constant": true,
              "metadata": { "position": { "x": 453, "y": 90 } }
            }
          },
          "output": { "unit": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 418, "y": 119 } }
        },
        "setinput4": {
          "id": "43b43afd-9e7d-44ba-aef7-6e9db4ed8744",
          "input": {
            "unit": { "ignored": false, "constant": false },
            "name": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": "R" },
              "metadata": { "position": { "x": 259, "y": 110 } }
            },
            "data": {
              "ignored": false,
              "constant": true,
              "metadata": { "position": { "x": 299, "y": 85 } }
            }
          },
          "output": { "unit": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 305, "y": 129 } }
        },
        "setinput5": {
          "id": "43b43afd-9e7d-44ba-aef7-6e9db4ed8744",
          "input": {
            "unit": { "ignored": false, "constant": false },
            "name": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": "x" },
              "metadata": { "position": { "x": 47, "y": 229 } }
            },
            "data": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": 100 },
              "metadata": { "position": { "x": 121, "y": 237 } }
            }
          },
          "output": { "unit": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 89, "y": 203 } }
        },
        "setinput6": {
          "id": "43b43afd-9e7d-44ba-aef7-6e9db4ed8744",
          "input": {
            "unit": { "ignored": false, "constant": false },
            "name": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": "y" },
              "metadata": { "position": { "x": 165, "y": 152 } }
            },
            "data": {
              "ignored": false,
              "constant": true,
              "data": { "ref": [], "data": 100 },
              "metadata": { "position": { "x": 242, "y": 208 } }
            }
          },
          "output": { "unit": { "ignored": false, "constant": false } },
          "metadata": { "position": { "x": 203, "y": 179 } }
        }
      },
      "merges": {
        "0": {
          "setinput0": { "output": { "unit": true } },
          "setinput3": { "input": { "unit": true } }
        },
        "1": {
          "setinput": { "input": { "data": true } },
          "foreach0": { "output": { "i": true } }
        },
        "2": {
          "appendchild": { "input": { "parent": true } },
          "removeallchildren": { "input": { "parent": true } },
          "div": { "output": { "_self": true } }
        },
        "3": {
          "setinput1": { "output": { "unit": true } },
          "setinput2": { "input": { "unit": true } }
        },
        "4": {
          "get": { "input": { "obj": true } },
          "foreach0": { "output": { "a[i]": true } },
          "get0": { "input": { "obj": true } }
        },
        "5": {
          "plan": { "output": { "0": true } },
          "removeallchildren": { "input": { "any": true } }
        },
        "6": {
          "setinput1": { "input": { "data": true } },
          "get": { "output": { "value": true } }
        },
        "7": {
          "appendchild": { "input": { "child": true } },
          "setinput": { "output": { "unit": true } }
        },
        "8": {
          "foreach0": { "input": { "a": true } },
          "plan": { "output": { "1": true } }
        },
        "9": {
          "length": { "output": { "length": true } },
          "setinput0": { "input": { "data": true } }
        },
        "10": {
          "mergedefault": { "output": { "a": true } },
          "div": { "input": { "style": true } }
        },
        "11": {
          "setinput2": { "output": { "unit": true } },
          "setinput": { "input": { "unit": true } }
        },
        "12": {
          "setinput2": { "input": { "data": true } },
          "get0": { "output": { "value": true } }
        },
        "13": {
          "setinput4": { "input": { "unit": true } },
          "setinput3": { "output": { "unit": true } }
        },
        "14": {
          "setinput4": { "output": { "unit": true } },
          "setinput6": { "input": { "unit": true } }
        },
        "15": {
          "setinput6": { "output": { "unit": true } },
          "setinput5": { "input": { "unit": true } }
        },
        "16": {
          "setinput5": { "output": { "unit": true } },
          "setinput1": { "input": { "unit": true } }
        }
      },
      "inputs": {
        "unit": {
          "plug": { "0": { "unitId": "setinput0", "pinId": "unit" } },
          "ref": false,
          "functional": true,
          "metadata": { "position": { "0": { "ext": { "x": 593, "y": 96 } } } }
        },
        "style": {
          "plug": {
            "0": { "unitId": "mergedefault", "pinId": "a", "kind": "input" }
          },
          "ref": false,
          "metadata": { "position": { "0": { "ext": { "x": -742, "y": 28 } } } }
        },
        "list": {
          "plug": {
            "0": { "unitId": "plan", "pinId": "a" },
            "1": { "unitId": "length", "pinId": "a" }
          },
          "ref": false,
          "functional": true,
          "metadata": {
            "position": {
              "0": { "ext": { "x": -165, "y": -113 } },
              "1": { "ext": { "x": 706, "y": 167 } }
            }
          }
        },
        "r": {
          "plug": {
            "0": { "unitId": "setinput3", "pinId": "data", "kind": "input" }
          },
          "ref": false,
          "functional": true,
          "metadata": { "position": { "0": { "ext": { "x": 454, "y": 66 } } } }
        },
        "R": {
          "plug": {
            "0": { "unitId": "setinput4", "pinId": "data", "kind": "input" }
          },
          "ref": false,
          "functional": true,
          "metadata": { "position": { "0": { "ext": { "x": 295, "y": 61 } } } }
        }
      },
      "outputs": {},
      "metadata": {
        "icon": "question",
        "description": "",
        "position": {
          "merge": {
            "0": { "x": 474, "y": 129 },
            "1": { "x": -181, "y": 40 },
            "3": { "x": -65, "y": 148 },
            "4": { "x": -74, "y": 17 },
            "5": { "x": -269, "y": -63 },
            "6": { "x": 0, "y": 83 },
            "7": { "x": -273, "y": 63 },
            "8": { "x": -171, "y": -36 },
            "9": { "x": 581, "y": 152 },
            "10": { "x": -628, "y": -6 },
            "11": { "x": -177, "y": 118 },
            "12": { "x": -98, "y": 105 },
            "13": { "x": 362, "y": 129 },
            "14": { "x": 255, "y": 157 },
            "15": { "x": 147, "y": 193 },
            "16": { "x": 39, "y": 173 }
          }
        }
      },
      "render": true,
      "component": {
        "subComponents": {
          "div": {
            "children": [],
            "childSlot": {},
            "width": 271.53515625,
            "height": 268.17578125
          }
        },
        "children": ["div"],
        "defaultWidth": 271.7164306640625,
        "defaultHeight": 268.17578125
      },
      "id": "becac68c-b30c-49a7-8894-b200c2f5c5dc"
    }
  }
}
