import { MusemeSourceVideo } from './overlay'
import { Unlisten } from './types'

/* eslint-disable no-console */

export type LiveCCSetup = {
  video: HTMLVideoElement
  canvas: HTMLCanvasElement
}

export type LiveCCCallbacks = {
  onOpen(): void
  onFrame(frame: string, timestamp: number): void
  onMetadata(metadata: any, timestamp: number): void
}

export const rtcLiveSource = (
  url: string,
  source: MusemeSourceVideo,
  { video, canvas }: LiveCCSetup,
  { onOpen, onFrame, onMetadata }: LiveCCCallbacks
): Unlisten => {
  let timestamp: number
  let playheadMillis: number
  let playheadUtc: string
  let dataChannel: RTCDataChannel

  function setupDatachannel(channel: RTCDataChannel) {
    if (dataChannel) {
      dataChannel.close()
    }

    dataChannel = channel

    channel.onopen = () => {
      console.log('Data channel', channel, 'open!')
    }
    channel.onclose = () => {
      console.log('Data channel', channel, 'closed')
    }
    channel.onclosing = () => {
      console.log('Data channel', channel, 'closing')
    }
    channel.onmessage = (event) => {
      const eventJson = JSON.parse(event.data)

      onMetadata({ detected_objects: eventJson.data.metadata }, eventJson.time)
    }
  }

  const rtc = new RTCPeerConnection()

  rtc.addTransceiver('audio', { direction: 'recvonly' })
  rtc.addTransceiver('video', { direction: 'recvonly' })

  rtc.ondatachannel = (e) => {
    setupDatachannel(e.channel)
  }

  setupDatachannel(rtc.createDataChannel('JSON', { protocol: 'text' }))

  let frame: number

  rtc.ontrack = (e) => {
    const stream = e.streams[0]

    video.srcObject = stream

    const ctx = canvas.getContext('2d')

    const tick = () => {
      canvas.width = video.width
      canvas.height = video.height

      const bbox = source.getRect()

      const rx = video.videoWidth / bbox.width
      const ry = video.videoHeight / bbox.height

      ctx.drawImage(video, 0, 0, video.videoWidth / rx, video.videoHeight / ry)

      const imageDataUrl = canvas.toDataURL('image/png')

      timestamp = playheadMillis + video.currentTime * 1000

      onFrame(imageDataUrl, timestamp)

      frame = requestAnimationFrame(tick)
    }

    tick()

    onOpen()
  }

  rtc
    .createOffer({
      offerToReceiveVideo: true,
      offerToReceiveAudio: false,
    })
    .then(async (offer) => {
      rtc.setLocalDescription(offer)

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/sdp',
        },
        body: offer.sdp,
      })

      playheadMillis = parseInt(response.headers.get('Playhead-Millis'))
      playheadUtc = response.headers.get('Playhead-Utc')

      const answer = await response.text()

      if (rtc.signalingState === 'closed') {
        return
      }

      rtc.setRemoteDescription({ type: 'answer', sdp: answer })
    })

  return () => {
    if (dataChannel.readyState !== 'closed') {
      dataChannel.close()
    }

    if (rtc.signalingState !== 'closed') {
      rtc.close()
    }

    cancelAnimationFrame(frame)
  }
}

export const wsLiveSource = (
  url: string,
  { onOpen, onFrame, onMetadata }: LiveCCCallbacks
): Unlisten => {
  const socket = new WebSocket(url)

  socket.onopen = (event: MessageEvent) => {
    // console.log('onopen', event.data)

    onOpen()
  }

  socket.addEventListener('message', (event: MessageEvent) => {
    const { data: data_ } = event

    if (typeof data_ === 'string') {
      const obj = JSON.parse(data_)

      const { time, track, trackid, type, data } = obj

      if (data) {
        const parsedData = typeof data === 'string' ? JSON.parse(data) : data

        const { metadata } = parsedData

        if (metadata) {
          const parsedMetadata = JSON.parse(metadata)

          const { mjpg } = parsedMetadata

          onFrame(`data:image/jpg;base64,${mjpg}`, time)
          onMetadata(parsedMetadata, time)
        }
      }
    }
  })

  return () => {
    if (socket.readyState !== socket.CLOSED) {
      socket.close()
    }
  }
}
