export async function downloadURL(name: string, url: string) {
  const a = document.createElement('a')

  document.body.appendChild(a)

  a.download = name
  a.href = url

  a.click()

  document.body.removeChild(a)
}

export function hexToRgba(hex: string) {
  const bigInt = parseInt(hex.substring(1), 16)

  let r: number
  let g: number
  let b: number
  let a: number = 255

  if (hex.length === 7) {
    r = (bigInt >> 16) & 255
    g = (bigInt >> 8) & 255
    b = bigInt & 255
  } else if (hex.length === 9) {
    r = (bigInt >> 24) & 255
    g = (bigInt >> 16) & 255
    b = (bigInt >> 8) & 255
    a = bigInt & 255
  } else {
    throw new Error('invalid HEX color')
  }

  return [r, g, b, a]
}

export function isValidUrl(url: string): boolean {
  return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
    url
  )
}

export function clamp(x: number, min: number, max: number): number {
  return Math.max(min, Math.min(x, max))
}
